import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { ITable, TodaysIndex, months } from '../../../types/myTables.types';

export default function Table({
	lastSelectedColor,
	newTableData,
	componentUsed,
	isHorizontal
}: {
	lastSelectedColor?: string;
	newTableData: ITable;
	componentUsed?: string;
	isHorizontal?: boolean;
}) {
	const dayCountOfMonth = 31;
	const daysArray = Array.from({ length: dayCountOfMonth }, (_, index) => index + 1);
	const currentDate = new Date();
	const todaysIndex: TodaysIndex = {
		day: String(currentDate.getDate()),
		month: String(currentDate.getMonth() + 1),
		year: String(currentDate.getFullYear())
	};
	const searchParams = new URLSearchParams(location.search);
	const isCreate = searchParams.get('isCreate') === 'true';
	const [extendedTableData, setExtendedTableData] = useState(newTableData);

	useEffect(() => {
		const updatedTableData = {
			...newTableData,
			dataRanges: newTableData.dataRanges.map((dataRange) => ({
				...dataRange,
				activeDays:
					dataRange.activeDays &&
					dataRange.activeDays.map((activeDay) => ({
						...activeDay,
						hexColorCode: dataRange.hexColorCode
					}))
			}))
		};
		setExtendedTableData(updatedTableData);
	}, [newTableData]);

	const calculateCurrentDayInYear = (day: number, month: number, year: number) => {
		let totalDays = day;
		for (let i = 1; i < month; i++) {
			if (i === 2) {
				totalDays += year % 4 === 0 ? 29 : 28;
			} else if (i === 4 || i === 6 || i === 9 || i === 11) {
				totalDays += 30;
			} else {
				totalDays += 31;
			}
		}
		return totalDays;
	};

	const dayMonthCalculations = daysArray.map((day) => {
		return months.map((month, monthIndex) => {
			const isCurrentDayInYear =
				calculateCurrentDayInYear(Number(todaysIndex.day), Number(todaysIndex.month), Number(todaysIndex.year)) ===
				calculateCurrentDayInYear(day, monthIndex + 1, Number(todaysIndex.year));

			const isDayExceedsMonth = month.dayCount && day > month.dayCount;

			return { isCurrentDayInYear, isDayExceedsMonth };
		});
	});

	return (
		<div
			className={` ${isCreate && 'hidden md:block'}  ${componentUsed === 'myJournal' ? 'w-[200px] h-[480px]' : componentUsed === 'tableDetails' ? '' : `${isHorizontal ? '' : 'w-9/12 sm:w-8/12 '}`}`}
		>
			{!isHorizontal ? (
				<div
					className={`flex justify-center ${componentUsed === 'myJournal' ? 'h-[14px] mt-[2px] ml-[1px]' : 'h-[20px]'}`}
				>
					<div className="grid grid-cols-1">
						<div className={`${componentUsed === 'myJournal' ? 'w-[14px]' : 'h-5 w-5'}`}></div>
					</div>
					<div
						className={`grid  place-content-around grid-cols-12 ${componentUsed === 'myJournal' ? 'gap-[1px] ' : componentUsed === 'tableDetails' ? `gap-1  min-w-[264px] ` : 'gap-[1.5px] '}`}
					>
						{months.map((month, monthIndex) => (
							<div
								key={monthIndex}
								className={`flex justify-center items-center ${componentUsed === 'myJournal' ? 'h-[14px] w-[14px]' : 'h-4 w-4'}`}
							>
								<span
									className={` ${componentUsed === 'myJournal' ? 'text-2xs dark:text-black' : 'text-xs dark:text-white'} text-center`}
								>
									{month.firstLetter}
								</span>
							</div>
						))}
					</div>
				</div>
			) : (
				<div
					className={`flex ${componentUsed === 'myJournal' ? 'gap-[1px] ml-[1px]' : componentUsed === 'tableDetails' ? 'gap-[2px] md:gap-[3px] lg:gap-1  justify-end sm:justify-start  ml-1 sm:ml-0' : 'gap-[1.5px] justify-center min-w-[264px] xl:items-end'}`}
				>
					<div className="grid grid-cols-1">
						<div
							className={`${componentUsed === 'myJournal' ? 'w-[14px]' : 'h-5 w-5 sm:w-4 sm:h-4 md:h-5 md:w-3  xl:w-5 '}`}
						></div>
					</div>
					{daysArray.map((day, dayIndex) => (
						<div
							key={dayIndex}
							className={`grid grid-cols-1 ${componentUsed === 'myJournal' ? 'h-[14px] w-[14px]' : ` ${componentUsed === 'myTables' ? 'h-2.5 w-2.5 sm:h-4 sm:w-4 xl:w-4 xl:h-4 ' : 'h-2.5 w-2.5 sm:h-4 sm:w-4 md:h-5 md:w-5'}`}`}
						>
							<span
								className={`${componentUsed === 'myJournal' ? 'text-2xs dark:text-black' : `dark:text-white hidden sm:text-xs sm:block ${componentUsed === 'myTables' ? 'sm:text-2xs md:text-xs xl:text-2xs' : 'sm:text-2xs md:text-xs'} `} text-center`}
							>
								{day}
							</span>
						</div>
					))}
				</div>
			)}

			<div
				className={`flex w-full justify-center ${componentUsed === 'myJournal' ? 'h-[14px] mt-[1px]' : componentUsed === 'myTables' && !isHorizontal ? 'max-h-[540px] ' : ''}`}
			>
				{!isHorizontal ? (
					<div
						className={`grid grid-cols-1  ${componentUsed === 'myJournal' ? 'gap-[1px] ml-[1px]' : componentUsed === 'tableDetails' ? 'gap-1' : 'gap-[1.5px]'}`}
					>
						{daysArray.map((day, dayIndex) => (
							<div
								key={dayIndex}
								className={`flex justify-center items-center ${componentUsed === 'myJournal' ? 'h-[14px] w-[14px]' : 'h-4 w-4'}`}
							>
								<span
									className={`${componentUsed === 'myJournal' ? 'text-2xs dark:text-black' : 'text-2xs dark:text-white'} text-center`}
								>
									{day}
								</span>
							</div>
						))}
					</div>
				) : (
					<div
						className={`flex sm:justify-center ${componentUsed === 'myJournal' ? 'h-[14px] mt-[2px] ml-[1px]' : ` ${componentUsed === 'tableDetails' ? 'max-h-[284px]' : 'max-h-[209px]'}`}`}
					>
						<div
							className={`grid  place-content-around grid-cols-1 ${componentUsed === 'myJournal' ? 'gap-[1px] ' : componentUsed === 'tableDetails' ? 'gap-[2px] md:gap-[3px] lg:gap-1  sm:ml-0 md-ml-2' : ` ${componentUsed === 'myTables' ? 'gap[1.5px]' : 'ml-2 gap-[2px]'}  `}`}
						>
							{months.map((month, monthIndex) => (
								<div
									key={monthIndex}
									className={`flex justify-center items-center ${componentUsed === 'myJournal' ? 'h-[14px] w-[14px]' : `h-2.5 w-2.5 sm:h-4 sm:w-4 ${componentUsed === 'myTables' ? 'xl:w-4 xl:h-4' : ''}`}`}
								>
									<span
										className={`dark:text-white ${componentUsed === 'myJournal' ? 'text-2xs' : 'text-2xs sm:text-xs'} text-center`}
									>
										{month.firstLetter}
									</span>
								</div>
							))}
						</div>
					</div>
				)}

				<div
					className={`grid place-content-around ${isHorizontal ? 'grid-rows-12 grid-flow-col' : 'grid-cols-12'}  ${componentUsed === 'myJournal' ? ' ml-[2px]' : componentUsed === 'tableDetails' ? (isHorizontal ? 'gap-[2px] md:gap-[3px] lg:gap-1  min-w-[264px] ml-1 sm:ml-0 lg:ml-2' : 'gap-1') : `gap-[1.5px]  max-w-[208.5px]' ml-1 `}`}
				>
					{daysArray.map((day, dayIndex) => (
						<React.Fragment key={dayIndex}>
							{months.map((_month, monthIndex) => {
								const activeDay = extendedTableData.dataRanges
									.flatMap((dataRange) => dataRange.activeDays)
									.find(
										(activeDay) => activeDay && activeDay.dayIndex === day && activeDay.monthIndex === monthIndex + 1
									);

								return (
									<div
										key={`${day}-${monthIndex}`}
										className={classNames(
											`relative ${componentUsed === 'myJournal' ? `h-[15px] w-[15px] border-r border-black border-b text-xs ${monthIndex === 0 ? 'border-l' : ''} ${dayIndex === 0 ? 'border-t' : ''}` : isHorizontal ? ` ${componentUsed === 'myTables' || componentUsed === 'createTable' ? 'h-[9px] w-[9px] sm:h-4 sm:w-4 xl:w-4 xl:h-4' : 'h-2.5 w-2.5 sm:h-4 sm:w-4 md:h-5 md:w-5'} rounded-sm sm:rounded-md` : ` ${componentUsed === 'myTables' ? 'h-4 w-4 ' : 'h-5 w-5 '}rounded-md`} `,
											{
												'border border-green-500':
													dayMonthCalculations[dayIndex][monthIndex].isCurrentDayInYear &&
													componentUsed !== 'myJournal',
												'bg-lt-cream dark:bg-lt-dark-popupBackground':
													!dayMonthCalculations[dayIndex][monthIndex].isCurrentDayInYear &&
													!activeDay &&
													componentUsed === 'myTables',
												'bg-lt-lightCream dark:bg-lt-dark-popupBackground':
													!dayMonthCalculations[dayIndex][monthIndex].isCurrentDayInYear &&
													!activeDay &&
													componentUsed == 'tableDetails',
												'bg-[#bababa] opacity-50 dark:bg-[#494949]':
													dayMonthCalculations[dayIndex][monthIndex].isDayExceedsMonth
											}
										)}
										style={{
											backgroundColor: activeDay
												? activeDay.hexColorCode
												: dayMonthCalculations[dayIndex][monthIndex].isCurrentDayInYear
													? lastSelectedColor
													: undefined,
											boxShadow:
												dayMonthCalculations[dayIndex][monthIndex].isCurrentDayInYear && componentUsed !== 'myJournal'
													? '0 0 5px 2px rgba(0, 255, 0, 0.5)'
													: undefined
										}}
									>
										{dayMonthCalculations[dayIndex][monthIndex].isDayExceedsMonth && (
											<svg className="absolute inset-0 w-full h-full" viewBox="0 0 10 10">
												<line x1="1" y1="1" x2="9" y2="9" stroke="#999595" strokeWidth="1" />
											</svg>
										)}
									</div>
								);
							})}
						</React.Fragment>
					))}
				</div>
			</div>
		</div>
	);
}
