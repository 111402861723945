import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './customBaseQuery';

interface SubscribeReq {
	email: string;
}

interface SubscribeRes {
	message: string;
}

export const emailApi = createApi({
	reducerPath: 'emailApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		subscribe: builder.mutation<SubscribeRes, SubscribeReq>({
			query: (body) => ({
				url: `api/email-subs/subscribe`,
				method: 'POST',
				body
			})
		})
	})
});

export const { useSubscribeMutation } = emailApi;
