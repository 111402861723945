import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IActiveDay, ITable } from '../../types/myTables.types';

interface Pagination {
	page: number;
	totalPages: number;
	totalTables: number;
}
interface TableState {
	myTables: ITable[];
	pagination: Pagination;
	isLoading: boolean;
	searchQuery?: string;
}

const initialState: TableState = {
	myTables: [],
	pagination: { page: 1, totalPages: 1, totalTables: 0 },
	isLoading: true,
	searchQuery: ''
};

const myTablesSlice = createSlice({
	name: 'myTables',
	initialState,
	reducers: {
		getTablesSuccess(state, action: PayloadAction<{ tables: ITable[]; pagination: Pagination; query: string }>) {
			if (action.payload.pagination.page === 1) {
				state.myTables = action.payload.tables;
			} else {
				state.myTables = [...state.myTables, ...action.payload.tables];
			}
			state.pagination = action.payload.pagination;
			state.searchQuery = action.payload.query;
		},
		setLoading(state, action: PayloadAction<boolean>) {
			state.isLoading = action.payload;
		},
		createTableSuccess(state, action: PayloadAction<ITable>) {
			state.myTables = [action.payload, ...state.myTables];
			state.pagination.totalTables++;
		},
		updateIsTablePublicSuccess(
			state,
			action: PayloadAction<{
				_id: string;
				isTablePublic: boolean;
			}>
		) {
			const tableIndex = state.myTables.findIndex((table) => table._id === action.payload._id);
			state.myTables[tableIndex].isTablePublic = action.payload.isTablePublic;
		},
		updateActiveDaysSuccess(
			state,
			action: PayloadAction<{
				_id: string;
				activeDay: IActiveDay;
				dataRangeId: string;
			}>
		) {
			const tableIndex = state.myTables.findIndex((table) => table._id === action.payload._id);
			const matchingDataRangeIndex = state.myTables[tableIndex].dataRanges.findIndex(
				(dataRange) => dataRange._id === action.payload.dataRangeId
			);
			state.myTables[tableIndex].dataRanges[matchingDataRangeIndex].activeDays?.push(action.payload.activeDay);
		},
		deleteTableSuccess(state, action: PayloadAction<string>) {
			state.myTables = state.myTables.filter((table) => table._id !== action.payload);
		},
		updateTableNotesSuccess(
			state,
			action: PayloadAction<{
				_id: string;
				notes: string;
			}>
		) {
			const tableIndex = state.myTables.findIndex((table) => table._id === action.payload._id);
			state.myTables[tableIndex].notes = action.payload.notes;
		},
		deleteTodaysColorSuccess(
			state,
			action: PayloadAction<{
				_id: string;
				activeDayId?: string;
				dataRangeId?: string;
			}>
		) {
			const tableIndex = state.myTables.findIndex((table) => table._id === action.payload._id);
			const matchingDataRangeIndex = state.myTables[tableIndex].dataRanges.findIndex(
				(dataRange) => dataRange._id === action.payload.dataRangeId
			);
			state.myTables[tableIndex].dataRanges[matchingDataRangeIndex].activeDays = state.myTables[tableIndex].dataRanges[
				matchingDataRangeIndex
			].activeDays?.filter((activeDay) => activeDay._id !== action.payload.activeDayId);
		},
		clearSearchQuery(state) {
			state.searchQuery = '';
		}
	}
});

export const {
	createTableSuccess,
	getTablesSuccess,
	updateActiveDaysSuccess,
	deleteTableSuccess,
	updateIsTablePublicSuccess,
	updateTableNotesSuccess,
	deleteTodaysColorSuccess,
	setLoading,
	clearSearchQuery
} = myTablesSlice.actions;
export default myTablesSlice.reducer;
