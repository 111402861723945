import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from '/src/assets/logo.svg';

export default function RefundPolicyTr() {
	const isCartDomain = window.location.hostname.includes('cart.');
	const pathname = window.location.pathname;
	const parts = pathname.split('/');
	const language = parts[1];

	return (
		<div>
			<Helmet>
				<title>Life on Track - Refund Policy</title>
				<meta name="description" content="Refund policy of Life on Track." />
			</Helmet>
			<header className="sticky top-0 z-50 w-full h-[70px] px-4 md:px-12 flex items-center justify-between border-b  bg-lt-lightCream dark:bg-lt-dark-secondary-bg">
				<div className="flex justify-start items-center md:gap-2 w-4/12 md:w-1/4">
					<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
					{isCartDomain ? (
						<a
							href="https://lifeontrack.co"
							className="text-lt-beige dark:text-white font-extrabold text-sm sm:text-2xl"
						>
							Life on Track
						</a>
					) : (
						<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-sm sm:text-2xl">
							Life on Track
						</Link>
					)}
				</div>
				<div className="hidden md:flex justify-between base:justify-center base:gap-10 md:w-1/2">
					<Link
						to="/en/privacy-policy"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Privacy Policy
					</Link>
					<Link
						to="/en/terms-of-service"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Terms of Service
					</Link>
					{isCartDomain ? (
						<a
							href="https://lifeontrack.co"
							className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
						>
							Home
						</a>
					) : (
						<Link
							to="/"
							className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
						>
							Home
						</Link>
					)}
				</div>
				<div className="navbar w-1/3 flex justify-center md:hidden">
					<div className="navbar-center md:hidden">
						<ul className="menu menu-md menu-horizontal px-1">
							<li>
								<details>
									<summary className="text-lt-beige dark:text-[#5D4037] active:bg-lt-lightCream active:text-lt-beige dark:active:bg-transparent">
										Menu
									</summary>
									<ul className="flex flex-col text-xs sm:text-base font-semibold justify-between text-black dark:text-white dark:bg-lt-dark-popupBackground  divide-y divide-lt-beige dark:divide-[#6E6E40] bg-lt-lightCream px-0 ">
										<li className="px-4">
											<Link
												to="/en/privacy-policy"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Privacy Policy
											</Link>
										</li>
										<li className="px-4">
											<Link
												to="/en/terms-of-service"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Terms of Service
											</Link>
										</li>
										<li className="px-4">
											{isCartDomain ? (
												<a
													href="https://lifeontrack.co"
													className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
												>
													Home
												</a>
											) : (
												<Link
													to="/"
													className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
												>
													Home
												</Link>
											)}
										</li>
									</ul>
								</details>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex justify-end gap-1 sm:gap-4 text-xs sm:text-base md:text-xl w-2/12 md:w-1/4">
					<Link
						className={`${language === 'en' ? 'text-lt-beige dark:text-[#5D4037]' : 'text-black dark:text-white'} hover:text-lt-beige}`}
						to="/en/refund-policy"
					>
						EN
					</Link>
					/
					<Link
						className={`${language === 'tr' ? 'text-lt-beige dark:text-[#5D4037]' : 'text-black dark:text-white'} hover:text-lt-beige}`}
						to="/tr/refund-policy"
					>
						TR
					</Link>
				</div>
			</header>
			<div className="max-w-3xl mx-auto p-6 mt-5  h-screen overflow-y-auto pb-40">
				<h1 className="text-xl text-center font-bold mb-4">İPTAL, İADE VE TESLİMAT KOŞULLARI</h1>
				<p className="mb-6">
					İşbu İptal ve İade Politikası (“<strong>Politika</strong>”), Kaan Mert (“Kaan Software Studio”) olarak
					kullanıcılarımıza sunduğumuz ürün ve hizmetlere yönelik iptal ve iade koşullarını içermektedir.
				</p>

				<ol className="list-decimal list-inside mb-6">
					<li className="mb-4">
						İşbu Politika, Mesafeli Satış Sözleşmesi'nin ("<strong>Sözleşme</strong>") ayrılmaz bir parçası olup;
						Sözleşme'de yer alan tanımlar Politika'da da geçerlidir.
					</li>
					<li className="mb-4">
						Life on Track üzerinden Ürünlerimizi/Hizmetlerimizi satın aldığınız takdirde, Kullanıcı Sözleşmesi’ni kabul
						etmiş sayılırsınız.
					</li>
					<li className="mb-4">
						Kaan Software Studio, Life on Track üzerinden Mesafeli Sözleşmeler Yönetmeliği’nin “Cayma Hakkının
						İstisnaları” başlıklı 15. maddesinin 1. fıkrasının (h) bendinde belirtilen “cayma hakkı süresi sona ermeden
						önce, tüketicinin onayı ile ifasına başlanan hizmetlere ilişkin sözleşmeler” ve aynı maddenin 1. fıkrasının
						(ğ) bendinde belirtilen “elektronik ortamda anında ifa edilen hizmetler ile tüketiciye anında teslim edilen
						gayri maddi mallara ilişkin sözleşmeler” kapsamına giren Ürün/Hizmetler sunmaktadır. Bu sebeple satın
						aldığınız Ürün/Hizmet bakımından Yönetmelik uyarınca cayma hakkından yararlanamazsınız. Ancak üyeliğinizi
						her zaman iptal edebilirsiniz. İptal halinde ödemesi yapılan dönemin sonuna kadar Ürün/Hizmetlerden
						yararlanmaya devam edilebilecektir.
					</li>
					<li className="mb-4">
						Kullanıcı, sunulan hizmetin “Elektronik ortamda teslim edilebilir ürün” kategorisinde olması sebebiyle iade
						yapılamayacağını kabul eder. Ürün/Hizmetlerin niteliği gereği fiziksel bir teslimat yapılmayacaktır.
					</li>
				</ol>
			</div>
		</div>
	);
}
