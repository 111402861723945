import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFollowRequest, IProfile, PromoCodeResponse, SubscriptionType } from '../../services/api/profile';
import { IAchievement } from '../../types/achievement.types';
import { IFriend, IStreakDay } from '../../types/profile.types';
import { JournalPage } from '../../views/Dashboard/MyJournal/ItemTypes';
import { IFriendsSticker, ISticker } from '../../types/sticker.types';

interface ProfileState {
	profile: IProfile;
	followRequests: IFollowRequest[];
	stickers: ISticker[];
	friendsStickers: IFriendsSticker[];
}

const initialState: ProfileState = {
	profile: {
		_id: '',
		username: '',
		email: '',
		profileImgUrl: '',
		profileBackgroundUrl: '',
		stickers: [],
		achievements: [
			{
				_id: '',
				isClaimed: false
			}
		],
		tables: [],
		followings: [],
		followers: [],
		streakDay: {
			dayCount: 0,
			lastStreakDate: new Date(),
			lastClaimDate: new Date()
		},
		subscription: {
			subscriptionType: SubscriptionType.FREE,
			subscriptionEndDate: new Date(),
			subscriptionStartDate: new Date(),
			isTrial: false,
			_id: ''
		},
		myJournal: [],
		appMoney: 0,
		createdAt: new Date()
	},
	followRequests: [],
	stickers: [],
	friendsStickers: []
};

const profileSlice = createSlice({
	name: 'profile',
	initialState,
	reducers: {
		getMyProfileSuccess(state, action: PayloadAction<IProfile>) {
			state.profile = action.payload;
		},
		uploadProfileImageSuccess(state, action: PayloadAction<string>) {
			state.profile.profileImgUrl = action.payload;
		},
		uploadProfileBackgroundSuccess(state, action: PayloadAction<string>) {
			state.profile.profileBackgroundUrl = action.payload;
		},
		achievementEarnedSuccess(state, action: PayloadAction<IAchievement[]>) {
			action.payload.forEach((achievement) => {
				if (!state.profile.achievements.find((a) => a._id === achievement._id)) {
					state.profile.achievements.push({ _id: achievement._id ?? '', isClaimed: false });
				}
			});
		},
		responseFollowRequestSuccess(state, action: PayloadAction<IFollowRequest>) {
			state.followRequests = state.followRequests.filter((request) => request._id !== action.payload._id);
		},
		updateStreakDaySuccess(state, action: PayloadAction<IStreakDay>) {
			state.profile.streakDay.dayCount = action.payload.dayCount;
			state.profile.streakDay.lastStreakDate = new Date(action.payload.lastStreakDate);
		},
		claimStreakRewardSuccess(
			state,
			action: PayloadAction<{
				lastClaimDate: Date;
				appMoney: number;
			}>
		) {
			state.profile.appMoney = action.payload.appMoney;
			state.profile.streakDay.lastClaimDate = new Date(action.payload.lastClaimDate);
		},
		getMyJournalSuccess(state, action: PayloadAction<JournalPage[]>) {
			state.profile.myJournal = action.payload;
		},
		updateMyJournalSuccess(state, action: PayloadAction<JournalPage[]>) {
			state.profile.myJournal = action.payload;
		},
		createNewPageSuccess(state, action: PayloadAction<JournalPage>) {
			state.profile.myJournal.push(action.payload);
		},
		addNewTableToProfile(state, action: PayloadAction<string>) {
			state.profile.tables.push(action.payload);
		},
		deletePageSuccess(state, action: PayloadAction<number>) {
			state.profile.myJournal = state.profile.myJournal.filter((page) => page.pageNumber !== action.payload);
		},
		getMyStickersSuccess(state, action: PayloadAction<ISticker[]>) {
			state.stickers = action.payload;
		},
		getFriendsStickersSuccess(state, action: PayloadAction<IFriendsSticker[]>) {
			action.payload.forEach((sticker) => {
				if (!state.friendsStickers.find((s) => s._id === sticker._id)) {
					state.friendsStickers.push(sticker);
				}
			});
		},
		getOwnedStickersSuccess(state, action: PayloadAction<string[]>) {
			state.profile.stickers = action.payload;
		},
		buyShopItemSuccess(state, action: PayloadAction<ISticker>) {
			state.profile.stickers = [...state.profile.stickers, action.payload._id];
			state.stickers = [...state.stickers, action.payload];
			state.profile.appMoney -= action.payload.cost;
		},
		unfollowSuccess(state, action: PayloadAction<string>) {
			state.profile.followings = state.profile.followings.filter((following) => following !== action.payload);
		},
		claimAchievementSuccessProfile(state, action: PayloadAction<{ achId: string; reward: number }>) {
			state.profile.appMoney += action.payload.reward;
			const achievement = state.profile.achievements.find((a) => a._id === action.payload.achId);
			if (achievement) {
				achievement.isClaimed = true;
			}
		},
		deleteTableFromProfileSuccess(state, action: PayloadAction<string>) {
			state.profile.tables = state.profile.tables.filter((table) => table !== action.payload);
		},
		getMyFollowingsSuccess(state, action: PayloadAction<IFriend[]>) {
			action.payload.forEach((following) => {
				if (!state.profile.followings.includes(following._id)) {
					state.profile.followings.push(following._id);
				}
			});
		},
		getMyFollowersSuccess(state, action: PayloadAction<IFriend[]>) {
			action.payload.forEach((follower) => {
				if (!state.profile.followers.includes(follower._id)) {
					state.profile.followers.push(follower._id);
				}
			});
		},
		updateMyFollowersSuccess(state, action: PayloadAction<string>) {
			state.profile.followers.push(action.payload);
		},
		applyPromoCodeSuccess(state, action: PayloadAction<PromoCodeResponse>) {
			state.profile.subscription = action.payload.subscription;
		}
	}
});

export const {
	getMyProfileSuccess,
	uploadProfileBackgroundSuccess,
	uploadProfileImageSuccess,
	achievementEarnedSuccess,
	updateStreakDaySuccess,
	responseFollowRequestSuccess,
	getMyJournalSuccess,
	updateMyJournalSuccess,
	getMyStickersSuccess,
	buyShopItemSuccess,
	getOwnedStickersSuccess,
	claimAchievementSuccessProfile,
	unfollowSuccess,
	createNewPageSuccess,
	deletePageSuccess,
	deleteTableFromProfileSuccess,
	getFriendsStickersSuccess,
	addNewTableToProfile,
	getMyFollowingsSuccess,
	getMyFollowersSuccess,
	updateMyFollowersSuccess,
	claimStreakRewardSuccess,
	applyPromoCodeSuccess
} = profileSlice.actions;
export default profileSlice.reducer;
