import { Link } from 'react-router-dom';
import Logo from '/src/assets/logo.svg';

export default function Contact() {
	return (
		<div>
			<header className="sticky top-0 z-50 w-full h-[70px] px-4 md:px-12 flex items-center justify-between border-b  bg-lt-lightCream dark:bg-lt-dark-secondary-bg">
				<div className="flex justify-start items-center md:gap-2 w-1/2">
					<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
					<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-sm sm:text-2xl">
						Life on Track
					</Link>
				</div>
				<div className="flex justify-end base:justify-center base:gap-10 w-1/2">
					<Link
						to="/"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Home
					</Link>
				</div>
			</header>
			<div className="h-screen overflow-y-auto flex items-center justify-center mt-5">
				<div className="w-10/12 sm:w-full max-w-3xl flex flex-col justify-center items-center gap-5">
					<h1 className="font-bold text-3xl text-center">İletişim</h1>
					<table className="border border-gray-300 w-full">
						<tbody className="text-xs sm:text-base">
							<tr>
								<td className="border px-1 sm:px-4 py-2 font-semibold">Ticaret Ünvanı</td>
								<td className="border px-1 sm:px-4 py-2">Kaan MERT</td>
							</tr>
							<tr>
								<td className="border px-1 sm:px-4 py-2 font-semibold">Vergi Numarası</td>
								<td className="border px-1 sm:px-4 py-2">6180447161</td>
							</tr>
							{/* <tr>
								<td className="border px-1 sm:px-4 py-2 font-semibold">MERSİS Numarası</td>
								<td className="border px-1 sm:px-4 py-2"></td>
							</tr> */}
							<tr>
								<td className="border px-1 sm:px-4 py-2 font-semibold">Merkez Adresi</td>
								<td className="border px-1 sm:px-4 py-2">
									Yağcılar Mahallesi Saadet Sokağı No: 18/- Adapazarı/ Sakarya
								</td>
							</tr>
							<tr>
								<td className="border px-1 sm:px-4 py-2 font-semibold">E-posta Adresi</td>
								<td className="border px-1 sm:px-4 py-2">kaanmert.dev@gmail.com</td>
							</tr>
							<tr>
								<td className="border px-1 sm:px-4 py-2 font-semibold">Telefon Numarası</td>
								<td className="border px-1 sm:px-4 py-2">+90 530 061 42 82</td>
							</tr>
							<tr>
								<td className="border px-1 sm:px-4 py-2 font-semibold">Meslek Odası</td>
								<td className="border px-1 sm:px-4 py-2">
									Sakarya Elektronikçiler Bilgisayar Tamircileri Saatçiler Elektrikli Ev Aletleri Beyaz Eşya Satıcıları
									Esnaf ve Sanatkarlar Odası
								</td>
							</tr>
							<tr>
								<td className="border px-1 sm:px-4 py-2 font-semibold">Meslekle ilgili davranış kuralları </td>
								<td className="border px-1 sm:px-4 py-2">
									<a
										target="_blank"
										rel="noreferrer noopener"
										className="font-semibold text-blue-600 hover:underline"
										href="https://www.sesob.org.tr/misyon-vizyon"
									>
										https://www.sesob.org.tr/misyon-vizyon
									</a>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
