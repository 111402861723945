import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { IProfile } from './profile';

const baseQueryWithCsrf = fetchBaseQuery({
	baseUrl:
		process.env.NODE_ENV === 'development' ? import.meta.env.VITE_API_URL_TEST : import.meta.env.VITE_API_URL_PROD,
	credentials: 'include'
});

export const authApi = createApi({
	reducerPath: 'authApi',
	baseQuery: baseQueryWithCsrf,
	endpoints: (builder) => ({
		register: builder.mutation({
			query: (body) => ({
				url: 'auth/register',
				method: 'POST',
				body
			})
		}),
		login: builder.mutation({
			query: (body) => ({
				url: 'auth/login',
				method: 'POST',
				body
			})
		}),
		logout: builder.mutation({
			query: () => ({
				url: 'auth/logout',
				method: 'POST'
			})
		}),
		resendVerificationEmail: builder.mutation({
			query: (body) => ({
				url: 'auth/resend-verification-email',
				method: 'POST',
				body
			})
		}),
		verifyEmail: builder.mutation({
			query: (token) => ({
				url: `auth/verify-email?token=${token}`,
				method: 'GET'
			})
		}),
		forgotPassword: builder.mutation({
			query: (body) => ({
				url: 'auth/forgot-password',
				method: 'POST',
				body
			})
		}),
		resetPassword: builder.mutation({
			query: (body) => ({
				url: 'auth/reset-password',
				method: 'POST',
				body
			})
		}),
		refreshToken: builder.query({
			query: () => 'auth/refresh-token'
		}),
		getMe: builder.query<IProfile, void>({
			query: () => ({
				url: 'auth/get-me',
				method: 'GET'
			})
		}),
		googleLogin: builder.mutation({
			query: ({ token }) => ({
				url: 'auth/google-login',
				method: 'POST',
				body: {
					token
				}
			})
		}),
		googleSignup: builder.mutation({
			query: ({ token }) => ({
				url: 'auth/google-signup',
				method: 'POST',
				body: {
					token
				}
			})
		}),
		check: builder.query({
			query: () => 'auth/check'
		})
	})
});

export const {
	useRegisterMutation,
	useLoginMutation,
	useLogoutMutation,
	useResendVerificationEmailMutation,
	useVerifyEmailMutation,
	useForgotPasswordMutation,
	useResetPasswordMutation,
	useLazyRefreshTokenQuery,
	useLazyGetMeQuery,
	useGoogleLoginMutation,
	useGoogleSignupMutation,
	useLazyCheckQuery
} = authApi;
