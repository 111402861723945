import { useEffect, useState } from 'react';
import { IDataRange, ITable } from '../../../../types/myTables.types';
import Button from '../../../../components/button/button';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import Input from '../../../../components/input/input';
import './TableDetails.css';
import TableComponent from '../../../../components/myTables/table/Table';
import {
	useCreateTableMutation,
	useDeleteTableMutation,
	useDeleteTodaysColorMutation,
	useLazyGetTableQuery,
	useUpdateActiveDaysMutation,
	useUpdateIsTablePublicMutation,
	useUpdateTableNotesMutation
} from '../../../../services/api/myTables';
import toast from 'react-hot-toast';
import { isCustomErrorResponse } from '../../../../utils/errorUtils';
import { useDispatch, useSelector } from 'react-redux';
import {
	createTableSuccess,
	deleteTableSuccess,
	deleteTodaysColorSuccess,
	updateActiveDaysSuccess,
	updateIsTablePublicSuccess,
	updateTableNotesSuccess
} from '../../../../states/table/myTablesSlice';
import { RootState } from '../../../../states/store';
import NewDataRange from '../../../../components/myTables/newDataRange/NewDataRange';
import { IAchievement } from '../../../../types/achievement.types';
import AchievementToast from '../../../../components/profile/achievements/AchievementToast';
import {
	achievementEarnedSuccess,
	addNewTableToProfile,
	deleteTableFromProfileSuccess
} from '../../../../states/profile/profileSlice';
import {
	IconArrowLeft,
	IconArrowNarrowLeft,
	IconArrowRight,
	IconHelpOctagon,
	IconInfoCircle,
	IconTrash,
	IconTrashX,
	IconArrowBackUp
} from '@tabler/icons-react';
import { Doughnut, Line } from 'react-chartjs-2';
import {
	Chart as ChartJS,
	ArcElement,
	Tooltip,
	Legend,
	CategoryScale,
	LinearScale,
	Title,
	PointElement,
	LineElement
} from 'chart.js';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import CustomModule from '../../../../components/customModule/customModule';
import { Helmet } from 'react-helmet';
import { SubscriptionType } from '../../../../services/api/profile';
import categorizedIcons from './IconList';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title);

export default function TableDetails() {
	const { tableId } = useParams<{ tableId: string }>();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
	const templateData = location.state?.templateData;
	const [deleteTableRequest, { isLoading: deleteTableIsLoading }] = useDeleteTableMutation();
	const [getTable, { error, isLoading: getTableIsLoading }] = useLazyGetTableQuery();
	const [updateIsTablePublicRequest, { isLoading: updateTableIsPublicIsLoading }] = useUpdateIsTablePublicMutation();
	const [updateTableNotesRequest, { isLoading: updateTableNotesIsLoading }] = useUpdateTableNotesMutation();
	const [updateActiveDaysRequest, { isLoading: updateActiveDaysIsLoading }] = useUpdateActiveDaysMutation();
	const [lastSelectedColor, setLastSelectedColor] = useState<string>('');
	const [createTableRequest, { isLoading: createTableIsLoading }] = useCreateTableMutation();
	const myTables = useSelector((state: RootState) => state.myTables.myTables);
	const searchParams = new URLSearchParams(location.search);
	const isCreate = searchParams.get('isCreate') === 'true';
	const [isTodaysColorSelected, setIsTodaysColorSelected] = useState(false);
	const [isNotesUpdated, setIsNotesUpdated] = useState(false);
	const [isOpen, setIsOpen] = useState(false);
	const [deleteTodaysColorRequest, { isLoading: isDeleteTodaysColorLoading }] = useDeleteTodaysColorMutation();
	const profile = useSelector((state: RootState) => state.profile.profile);
	const isHorizontal = JSON.parse(localStorage.getItem('isHorizontal') || 'false');
	const [isTemplate, setIsTemplate] = useState(false);

	useEffect(() => {
		if (
			profile.subscription.subscriptionType === SubscriptionType.FREE &&
			profile.subscription.isTrial === false &&
			isCreate
		) {
			toast.error('Please upgrade your subscription to create a new table.');
			navigate('/dashboard/my-tables');
		}
	}, [profile.subscription.subscriptionType, isCreate]);

	const toggleDrawer = () => {
		setIsOpen((prevState) => !prevState);
	};
	const [newTableData, setNewTableData] = useState<ITable>({
		title: '',
		icon: '',
		isTablePublic: false,
		notes: '',
		dataRanges: [
			{
				hexColorCode: '',
				range: '',
				unit: ''
			},
			{
				hexColorCode: '',
				range: '',
				unit: ''
			}
		]
	});
	const theme = localStorage.getItem('theme');
	const activeDayCountByRange = newTableData.dataRanges.map((dataRange) => dataRange.activeDays?.length || 0);
	const labelsDoughnutData = newTableData.dataRanges.map((dataRange) => dataRange.range);
	const backgroundColors = newTableData.dataRanges.map((dataRange) => dataRange.hexColorCode);
	const hoverOffsets = newTableData.dataRanges.map((dataRange) => dataRange.activeDays?.length || 0);

	const doughnutData = {
		labels: labelsDoughnutData,
		datasets: [
			{
				label: 'Active Days Count',
				data: activeDayCountByRange,
				backgroundColor: backgroundColors,
				hoverOffset: hoverOffsets
			}
		]
	};

	const optionsDoughnutData = {
		plugins: {
			tooltip: {
				callbacks: {
					title: function (tooltipItems: any) {
						const tooltipItem = tooltipItems[0];
						const dataRange = newTableData.dataRanges[tooltipItem.dataIndex];
						return dataRange.unit ? `${dataRange.range} ${dataRange.unit}` : dataRange.range;
					},
					label: function (tooltipItem: any) {
						return `Count: ${tooltipItem.raw}`;
					}
				}
			},
			legend: {
				labels: {
					color: theme === 'dark' ? 'rgba(255, 255, 255, 0.87)' : 'rgba(0, 0, 0, 0.87)'
				}
			}
		}
	};

	useEffect(() => {
		if (templateData) {
			const clearedTemplateData = { ...templateData };
			delete clearedTemplateData.img;
			setIsTemplate(true);
			setNewTableData(clearedTemplateData);
		}
	}, [templateData]);

	const getMonthlyPerformance = (dataRanges: IDataRange[]) => {
		const monthlyData: { [key: string]: number } = {};

		dataRanges.forEach((dataRange) => {
			if (dataRange.activeDays) {
				dataRange.activeDays.forEach((activeDay) => {
					const monthYear = `${activeDay.monthIndex + 1}-${new Date().getFullYear()}`;
					if (!monthlyData[monthYear]) {
						monthlyData[monthYear] = 0;
					}
					monthlyData[monthYear]++;
				});
			}
		});

		const labels = Object.keys(monthlyData).sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
		const data = labels.map((label) => monthlyData[label]);

		return { labels, data };
	};

	const { labels, data } = getMonthlyPerformance(newTableData.dataRanges);

	const lineChartData = {
		labels,
		datasets: [
			{
				label: 'Number of Active Days',
				data,
				fill: false,
				borderColor: 'rgb(75, 192, 192)',
				tension: 0.1
			}
		]
	};

	const optionsLineChart = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
				labels: {
					color: theme === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)'
				}
			},
			title: {
				display: true,
				text: 'Monthly Performance',
				color: theme === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)'
			},
			scales: {
				x: {
					ticks: {
						color: theme === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)'
					}
				},
				y: {
					ticks: {
						color: theme === 'dark' ? 'rgb(255, 255, 255)' : 'rgb(0, 0, 0)'
					}
				}
			}
		}
	};

	useEffect(() => {
		const fetchTable = async () => {
			if (tableId && tableId !== '0') {
				const table = myTables.find((table) => table._id === tableId);
				if (table) {
					setNewTableData(table);
				} else {
					try {
						const response = await getTable(tableId).unwrap();
						if (response.achievements.length > 0) {
							setNewTableData(response.data);
						}
					} catch (err) {
						if (isCustomErrorResponse(error)) {
							toast.error(error.data.message);
						} else if (error instanceof Error) {
							toast.error(error.message);
						} else {
							toast.error('An unknown error occurred. Please try again later.');
						}
						navigate('/dashboard/not-found');
					}
				}
			}
		};
		if (!profile.tables.includes(tableId as string)) return;
		fetchTable();
	}, [myTables, tableId]);

	useEffect(() => {
		const checkIsTodaysColorSelected = () => {
			const date = new Date();
			const monthIndex = String(date.getMonth() + 1);
			const todaysIndex = String(date.getDate());

			const activeDay = newTableData.dataRanges
				.map((dataRange) => dataRange.activeDays)
				.flat()
				.find(
					(activeDay) => activeDay?.dayIndex === Number(todaysIndex) && activeDay.monthIndex === Number(monthIndex)
				);

			if (activeDay) {
				setIsTodaysColorSelected(true);
			}
		};
		checkIsTodaysColorSelected();
	}, [newTableData]);

	const deleteTable = async () => {
		try {
			if (!newTableData._id) return;
			const response = await deleteTableRequest(newTableData._id).unwrap();
			if (response) {
				navigate('/dashboard/my-tables');
				dispatch(deleteTableSuccess(newTableData._id));
				dispatch(deleteTableFromProfileSuccess(newTableData._id));
				setTimeout(() => {
					toast.success(response.message);
				}, 500);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		} finally {
			(document.getElementById('tableDetails-ays-modal') as HTMLDialogElement).close();
		}
	};

	const updateIsTablePublic = async (id: string) => {
		const updatedTable = { ...newTableData, _id: id };
		try {
			const response = await updateIsTablePublicRequest(id).unwrap();
			if (response) {
				setNewTableData({
					...updatedTable,
					isTablePublic: response.data.isTablePublic
				});
				updatedTable.isTablePublic = response.data.isTablePublic;
				dispatch(
					updateIsTablePublicSuccess({
						_id: id,
						isTablePublic: response.data.isTablePublic
					})
				);
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(`Table changed to ${!newTableData.isTablePublic === false ? 'private' : 'public'} successfully`);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const setTodaysColor = async (dataRangeId: string) => {
		try {
			if (!newTableData._id) return;
			if (!dataRangeId) return;
			const response = await updateActiveDaysRequest(dataRangeId).unwrap();
			if (response) {
				const matchingDataRange = response.data.dataRanges.find((dataRange) => dataRange._id === dataRangeId);
				if (matchingDataRange?.activeDays) {
					dispatch(
						updateActiveDaysSuccess({
							_id: newTableData._id,
							activeDay: matchingDataRange?.activeDays[matchingDataRange.activeDays.length - 1],
							dataRangeId: dataRangeId
						})
					);
				}
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const clearTodaysSelection = async () => {
		try {
			if (!newTableData._id) return;
			const date = new Date();
			const monthIndex = String(date.getMonth() + 1);
			const todaysIndex = String(date.getDate());

			const activeDay = newTableData.dataRanges
				.map((dataRange) => dataRange.activeDays)
				.flat()
				.find(
					(activeDay) => activeDay?.dayIndex === Number(todaysIndex) && activeDay.monthIndex === Number(monthIndex)
				);

			if (!activeDay) {
				toast.error("Today's color is not selected");
				return;
			}
			const activeDayId = activeDay._id;
			const dataRangeId = newTableData.dataRanges.find((dataRange) =>
				dataRange.activeDays?.find((activeDay) => activeDay._id === activeDayId)
			)?._id;
			const response = await deleteTodaysColorRequest({
				dataRangeId,
				activeDayId
			}).unwrap();
			if (response) {
				dispatch(
					deleteTodaysColorSuccess({
						_id: newTableData._id,
						dataRangeId,
						activeDayId
					})
				);
				setIsTodaysColorSelected(false);
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const createTable = async () => {
		const dataRanges = newTableData.dataRanges.filter((dataRange) => dataRange.range);
		dataRanges.forEach((dataRange) => {
			if (dataRange.range[0] === '-') {
				dataRange.range = dataRange.range.slice(1);
			}
			if (dataRange.range[dataRange.range.length - 1] === '-') {
				dataRange.range = dataRange.range.slice(0, dataRange.range.length - 1);
			}
		});
		const updatedTable = { ...newTableData, dataRanges };
		try {
			delete updatedTable.notes;
			const response = await createTableRequest(updatedTable).unwrap();
			if (response) {
				dispatch(createTableSuccess(response.data));
				dispatch(addNewTableToProfile(response.data._id as string));
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(response.message);
				setNewTableData({
					title: '',
					isTablePublic: false,
					notes: '',
					icon: '',
					dataRanges: [
						{
							hexColorCode: '',
							range: '',
							unit: ''
						},
						{
							hexColorCode: '',
							range: '',
							unit: ''
						}
					]
				});
				navigate('/dashboard/my-tables');
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement> | React.ChangeEvent<HTMLInputElement>) => {
		const value = e.target.value;
		setNewTableData({ ...newTableData, notes: value });
		setIsNotesUpdated(true);
	};

	const handleUpdateNotes = async (tableId: string, notes: string) => {
		try {
			const response = await updateTableNotesRequest({
				id: tableId,
				notes
			}).unwrap();
			if (response) {
				dispatch(
					updateTableNotesSuccess({
						_id: tableId,
						notes: response.data.notes as string
					})
				);
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
		setIsNotesUpdated(false);
	};

	const calculateConsistency = (dataRanges: IDataRange[], year: number): string => {
		if (!dataRanges || dataRanges.length === 0) {
			return '0';
		}

		const allActiveDays = dataRanges
			.flatMap(
				(dataRange) => dataRange.activeDays?.map((day) => new Date(year, day.monthIndex - 1, day.dayIndex)) || []
			)
			.filter((date) => !isNaN(date.getTime()))
			.sort((a, b) => a.getTime() - b.getTime());

		if (allActiveDays.length === 0) {
			return '0';
		}

		const firstActiveDay = allActiveDays[0];
		const today = new Date();

		const totalDays = Math.ceil((today.getTime() - firstActiveDay.getTime()) / (1000 * 60 * 60 * 24));

		const filledDays = allActiveDays.length;

		const consistency = (filledDays / totalDays) * 100;

		return consistency.toFixed(2);
	};

	const consistency = calculateConsistency(newTableData.dataRanges, 2024);

	const openAYSModule = () => {
		(document.getElementById('tableDetails-ays-modal') as HTMLDialogElement).showModal();
	};

	const clearNotes = () => {
		setNewTableData({ ...newTableData, notes: '' });
		setIsNotesUpdated(true);
	};

	const closeIconModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		(document.getElementById('icons_modal') as HTMLDialogElement)?.close();
	};

	const renderIconCategories = () => {
		return Object.entries(categorizedIcons).map(([category, icons]) => (
			<div key={category} className="mb-4">
				<h4 className="font-bold text-lg mb-2 text-lt-black dark:text-lt-dark-inputPlaceholder">
					{category.charAt(0).toUpperCase() + category.slice(1)}
				</h4>
				<div className="grid grid-cols-3 sm:grid-cols-4 gap-5">
					{icons.map((icon, index) => {
						const displayIcon = icon.replace(/^ti ti-/, '').replace(/-/g, ' ');

						return (
							<button
								onClick={() => {
									setNewTableData({ ...newTableData, icon });
									(document.getElementById('icons_modal') as HTMLDialogElement)?.close();
								}}
								key={index}
								className="hover:bg-lt-beige text-lt-black  dark:text-lt-white hover:text-lt-white dark:hover:bg-lt-dark-secondary-bg transition-colors duration-300 rounded-xl flex justify-center items-center border border-lt-beige dark:border-lt-dark-primary-border p-2 w-32 h-32"
							>
								<div className="flex flex-col items-center justify-center">
									<i className={`${icon} text-xl`}></i>
									<span>{displayIcon}</span>
								</div>
							</button>
						);
					})}
				</div>
			</div>
		));
	};

	return (
		<div className={`justify-center w-full lg:flex h-full`}>
			<Helmet>
				<title>Life on Track - Table Details</title>
				<meta name="description" content="View and manage your table details." />
			</Helmet>
			<dialog id="icons_modal" className="modal">
				<div className="p-0 modal-box md:min-w-[600px] md:min-h-[600px] bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground border border-lt-light-popupBorder dark:border-lt-dark-popupBorder">
					<div className="sticky top-0 bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground p-4 ">
						<form method="dialog">
							<button
								onClick={(e) => closeIconModal(e)}
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							>
								✕
							</button>
						</form>
						<h3 className="font-bold text-3xl">Icons</h3>
					</div>

					<div className="p-3">{renderIconCategories()}</div>
				</div>
			</dialog>
			<CustomModule
				id="tableDetails-ays-modal"
				title="Are you sure you want to delete this table?"
				description="Deleting this table will permanently delete all the data associated with it. This action cannot be undone."
				buttonText="Delete Table"
				onButtonClick={() => {
					deleteTable();
				}}
				isLoading={deleteTableIsLoading}
			/>
			<div
				className={`flex flex-col justify-start w-full max-w-[2000px] h-screen  overflow-y-auto pb-40 sm:pb-0 ${isChrome ? 'mt-[60px] sm:mt-0' : ''}`}
			>
				<div className="flex justify-start items-center">
					<Link
						to="/dashboard/my-tables"
						className={`w-14 sm:w-16 ml-4 ${isDeleteTodaysColorLoading || updateTableIsPublicIsLoading || deleteTableIsLoading || updateActiveDaysIsLoading || updateTableNotesIsLoading ? 'cursor-not-allowed pointer-events-none' : ''}`}
					>
						<IconArrowNarrowLeft className="text-lt-beige dark:text-lt-dark-primary-disabled-border w-14 h-14 sm:w-16 sm:h-16" />
					</Link>
				</div>

				{getTableIsLoading ? (
					<span className="loading loading-spinner loading-lg"></span>
				) : (
					<div
						className={`flex-1 flex ${isHorizontal ? 'flex-wrap sm:px-5 pb-10' : ' flex-col'} md:flex-row w-full  pb-40 md:pb-14`}
					>
						<div className="hidden sm:block">
							<Drawer
								open={isOpen}
								onClose={toggleDrawer}
								direction="bottom"
								className="bg-lt-lightCream dark:bg-lt-dark-primary-bg flex justify-center"
							>
								<div className="flex justify-around gap-2 w-full max-w-[2000px] items-center py-5">
									<div className="w-[200px] h-[200px]">
										<Doughnut className="doughnut dark:text-white" data={doughnutData} options={optionsDoughnutData} />
									</div>
									<div>
										<Line data={lineChartData} options={optionsLineChart} />
									</div>
									<div className="flex flex-col h-[200px] justify-center gap-2 text-lt-beige dark:text-white">
										<strong>Consistency:</strong>
										<div className="radial-progress" style={{ '--value': Number(consistency) }} role="progressbar">
											{consistency}%
										</div>
									</div>
								</div>
							</Drawer>
						</div>

						<div className={` ${!isCreate ? (isHorizontal ? 'flex w-full' : 'w-full') : 'hidden'} `}>
							<div
								className={`flex flex-col   ${isHorizontal ? 'items-center justify-center sm:justify-center lg:justify-center' : 'justify-start sm:justify-center'} w-full`}
							>
								<div className="w-full flex justify-between items-center lg:w-8/12 2xl:w-5/12 px-4">
									{!isCreate && (
										<>
											<div className="flex gap-2">
												<i className={`${newTableData?.icon} text-4xl`}></i>
												<h1 className="font-bold text-2xl sm:text-4xl flex items-center whitespace-nowrap overflow-hidden text-ellipsis">
													{newTableData.title}
												</h1>
											</div>

											<div className={`flex flex-col sm:justify-end justify-start ${!isHorizontal && 'md:hidden'}`}>
												<div className="flex  justify-between">
													<button
														data-tooltip-id="delete-table-tooltip"
														data-tooltip-content="Delete this table"
														data-tooltip-place="top"
														disabled={deleteTableIsLoading}
														type="button"
														onClick={() => openAYSModule()}
														className=" w-1/12 sm:w-5/12 disabled:text-gray-500 text-red-500 "
													>
														<IconTrashX className="w-5 h-5 sm:w-auto sm:h-auto " />
														<ReactTooltip id="delete-table-tooltip" />
													</button>
												</div>
											</div>
										</>
									)}
								</div>
								<div
									className={`flex flex-col gap-4 mt-5  ${isCreate && 'hidden'} ${!isHorizontal ? 'md:mt-8' : 'md:mt-0'}`}
								>
									<div className="flex justify-around text-lt-dark-inputPlaceholder">
										<IconArrowLeft />
										<h6 className="text-lt-beige dark:text-lt-white">2024</h6>

										<IconArrowRight />
									</div>
									<TableComponent
										isHorizontal={isHorizontal}
										newTableData={newTableData}
										lastSelectedColor={lastSelectedColor}
										componentUsed="tableDetails"
									/>
								</div>
							</div>
						</div>

						<div
							className={`w-full  px-4 sm:px-6 flex flex-col mt-5 sm:mt-0 ${!isCreate ? (isHorizontal ? ' w-full h-auto lg:w-10/12 2xl:w-5/12 justify-around 2xl:px-4 mx-auto' : ' lg:w-full md:mt-[72px]') : ''}  `}
						>
							<div className="flex justify-between">
								{isCreate ? (
									<div className={`flex w-full gap-4 min-h-20`}>
										<div className="flex w-3/4 gap-4 mt-2">
											<Input
												disabled={createTableIsLoading}
												value={newTableData.title}
												name="title"
												type="text"
												placeholder="Enter Table Title"
												className="p-2 w-full border border-lt-beige rounded-md"
												onChange={(e) => setNewTableData({ ...newTableData, title: e.target.value })}
											/>

											<Button
												disabled={createTableIsLoading}
												type={'button'}
												variant="btn-primary-light"
												className="relative w-1/10"
												onClick={() => (document.getElementById('icons_modal') as HTMLDialogElement)?.showModal()}
											>
												{newTableData.icon ? (
													<i className={`${newTableData.icon}`}></i>
												) : (
													<i className="ti ti-icons"></i>
												)}
											</Button>
										</div>
									</div>
								) : (
									<div className={`flex justify-between items-center w-11/12`}>
										<div
											className={`flex items-start start:items-center gap-2 ${!isHorizontal ? 'md:flex-row w-full justify-between items-center' : 'flex-col lg:flex-row'}`}
										>
											<div className="form-control">
												<label
													data-tooltip-id="table-is-public-tooltip"
													data-tooltip-content="If it's public, it will be shown in your journal."
													data-tooltip-place="top"
													className="label cursor-pointer"
												>
													<input
														disabled={updateTableIsPublicIsLoading || isDeleteTodaysColorLoading}
														type="checkbox"
														checked={newTableData.isTablePublic}
														onChange={() => {
															if (newTableData._id) updateIsTablePublic(newTableData._id);
														}}
														className="w-4 h-4 sm:w-6 sm:h-6 rounded-sm sm:rounded-md checkbox [--chkbg:theme(colors.lt-beige)] [--chkfg:#FAEDCD] dark:[--chkbg:theme(colors.lt-dark-primary-bg)] dark:[--chkfg:#FFFFFF] checked:border-none"
													/>

													<span className="text-xs sm:text-base label-text text-lt-dark-inputPlaceholder ml-2">
														{!newTableData.isTablePublic === false ? 'Make it Private' : 'Make it Public'}
													</span>
													<ReactTooltip id="table-is-public-tooltip" />
												</label>
											</div>
											<div className={` flex-col sm:justify-end justify-start hidden ${!isHorizontal && 'md:flex'}`}>
												<div className="flex  justify-between">
													<button
														data-tooltip-id="delete-table-tooltip"
														data-tooltip-content="Delete this table"
														data-tooltip-place="top"
														disabled={deleteTableIsLoading}
														type="button"
														onClick={() => openAYSModule()}
														className=" w-1/12 sm:w-5/12 disabled:text-gray-500 text-red-500 "
													>
														<IconTrashX className="w-5 h-5 sm:w-auto sm:h-auto " />
														<ReactTooltip id="delete-table-tooltip" />
													</button>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
							{isCreate ? (
								<NewDataRange
									isTemplate={isTemplate}
									newTableData={newTableData}
									setNewTableData={setNewTableData}
									setLastSelectedColor={setLastSelectedColor}
									createTableIsLoading={createTableIsLoading}
								/>
							) : (
								<div className={`${isHorizontal ? 'sm:h-2/5 ' : ' sm:h-2/5'} flex  flex-col w-full gap-6`}>
									{!isTodaysColorSelected ? (
										<div className="flex items-center gap-2 justify-start">
											<IconInfoCircle className="w-6 h-6 text-lt-dark-primary-text dark:text-lt-dark-primary-text text-lt-dark-inputPlaceholder" />
											<h6 className="text-xs sm:text-sm w-full sm:w-1/2 text-lt-dark-inputPlaceholder">
												You haven't selected today's color yet. Click on the color you want to set as today's color.
											</h6>
										</div>
									) : (
										<div className="flex items-center gap-2 justify-start">
											<IconInfoCircle className="w-6 h-6 text-lt-dark-primary-text dark:text-lt-dark-primary-text text-lt-dark-inputPlaceholder" />
											<h6 className="text-xs sm:text-sm w-full sm:w-1/2 text-lt-dark-inputPlaceholder">
												You have already selected today's color. Come back tomorrow to select a new color.
											</h6>
										</div>
									)}
									<div className={`h-full grid ${isHorizontal ? 'grid-cols-2 sm:grid-cols-3' : 'grid-cols-2'} gap-2`}>
										{newTableData.dataRanges.map((dataRange, index) => (
											<div key={index} className="flex items-center">
												<div className="relative">
													<button
														disabled={updateActiveDaysIsLoading || isTodaysColorSelected || isDeleteTodaysColorLoading}
														onClick={() => {
															if (dataRange._id) setTodaysColor(dataRange._id);
														}}
														style={{
															backgroundColor: dataRange.hexColorCode
														}}
														className="w-6 h-6 sm:w-10 sm:h-10 rounded-sm flex-shrink-0 disabled:opacity-50"
													></button>

													{isTodaysColorSelected &&
														dataRange.activeDays?.some((activeDay) => {
															const date = new Date();
															return (
																activeDay.dayIndex === date.getDate() && activeDay.monthIndex === date.getMonth() + 1
															);
														}) && (
															<button
																disabled={updateActiveDaysIsLoading || isDeleteTodaysColorLoading}
																onClick={() => clearTodaysSelection()}
																className="absolute -top-4 -right-4 bg-red-500 text-white p-1 rounded-full"
																type="button"
																data-tooltip-id="clear-todays-selection-tooltip"
																data-tooltip-content="Clear Today's Selection"
															>
																<IconArrowBackUp className="w-3.5 h-3.5 sm:w-4 sm:h-4" />
																<ReactTooltip id="clear-todays-selection-tooltip" />
															</button>
														)}
												</div>

												<div className="ml-2 flex items-center">
													<span
														className={`text-base sm:text-lg whitespace-nowrap overflow-hidden text-ellipsis ${updateActiveDaysIsLoading || isTodaysColorSelected || isDeleteTodaysColorLoading ? 'text-lt-dark-primary-disabled-text' : ''} `}
													>
														{dataRange.range} {dataRange?.unit}
													</span>
												</div>
											</div>
										))}
									</div>
								</div>
							)}
							{!isCreate && (
								<div className={` ${isHorizontal ? '' : 'mt-5'} `}>
									<button
										className="hidden sm:block underline text-lt-beige dark:text-lt-white my-6"
										onClick={toggleDrawer}
									>
										Show more details
									</button>
									<div className="flex flex-col gap-2">
										<div className="flex flex-col mt-4 sm:mt-0">
											<div className="flex justify-between w-full base:w-3/4 ">
												<label
													className="font-bold text-sm sm:text-lg dark:text-lt-dark-inputPlaceholder"
													htmlFor="note"
												>
													Notes:
												</label>
												<h6 className="text-lt-light-inputPlaceholder">
													{newTableData.notes?.length}
													/300
												</h6>
											</div>

											<div className="relative w-full base:w-3/4">
												<textarea
													value={newTableData.notes}
													onChange={handleNotesChange}
													maxLength={300}
													id="note"
													className="textarea textarea-bordered w-full max-h-64 dark:bg-lt-dark-popupBackground"
													placeholder="Enter notes here"
												></textarea>
												{newTableData.notes && newTableData.notes.length > 0 && (
													<button
														disabled={updateTableNotesIsLoading}
														onClick={clearNotes}
														className="text-red-500 disabled:text-gray-500"
													>
														<IconTrash className="absolute top-2 right-2  cursor-pointer" />
													</button>
												)}
											</div>
										</div>

										<Button
											disabled={updateTableNotesIsLoading || !isNotesUpdated}
											onClick={() => {
												if (newTableData._id) handleUpdateNotes(newTableData._id, newTableData.notes as string);
											}}
											className="w-1/2 text-xs  xl:w-1/4  sm:text-base"
											variant="btn-primary-light"
											type="button"
										>
											{updateTableNotesIsLoading ? (
												<span className="loading loading-spinner loading-xs"></span>
											) : (
												'Update Notes'
											)}
										</Button>
									</div>
								</div>
							)}

							{isCreate && (
								<div className="min-h-[325px] sm:min-h-11">
									<div className={`flex flex-col items-start`}>
										<div className="form-control w-auto inline-block">
											<label
												data-tooltip-id="create-table-is-public-tooltip"
												data-tooltip-content="If it's public, it will be shown in your journal."
												data-tooltip-place="top"
												className="label cursor-pointer flex justify-start w-auto inline-block"
											>
												<input
													disabled={createTableIsLoading}
													type="checkbox"
													checked={newTableData.isTablePublic}
													onChange={(e) => setNewTableData({ ...newTableData, isTablePublic: e.target.checked })}
													className="checkbox [--chkbg:theme(colors.lt-beige)] [--chkfg:#FAEDCD] dark:[--chkbg:theme(colors.lt-dark-primary-bg)] dark:[--chkfg:#FFFFFF] checked:border-none"
												/>
												<span className="label-text ml-2 text-lt-dark-inputPlaceholder">
													{!newTableData.isTablePublic === false ? 'Make it Private' : 'Make it Public'}
												</span>
												<IconHelpOctagon className="w-6 h-6 text-lt-dark-inputPlaceholder  ml-2" />
												<ReactTooltip id="create-table-is-public-tooltip" />
											</label>
										</div>

										<Button
											disabled={
												createTableIsLoading ||
												!newTableData.title ||
												!newTableData.dataRanges[0].range ||
												!newTableData.dataRanges[1].range
											}
											variant="btn-primary-light"
											type="button"
											onClick={createTable}
											className="w-full lg:w-5/12"
										>
											{createTableIsLoading ? (
												<span className="loading loading-spinner loading-xs"></span>
											) : (
												'Create Table'
											)}
										</Button>
									</div>
								</div>
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
