import { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import toast from 'react-hot-toast';
import { IconEye, IconEyeOff } from '@tabler/icons-react';
import Input from '../../components/input/input';
import Button from '../../components/button/button';
import { useGoogleSignupMutation, useRegisterMutation } from '../../services/api/auth';
import { RegisterRequest } from '../../types/auth.types';
import { CustomErrorResponse } from '../../types/error.types';
import PasswordPopup from '../../components/password-popup/PasswordPopup';
import { useDispatch } from 'react-redux';
import { setEmail } from '../../states/auth/emailSlice';
import { GoogleLogin } from '@react-oauth/google';
import './Auth.css';
import { Helmet } from 'react-helmet';
import Logo from '/src/assets/logo.svg';

export default function Register() {
	const isCartDomain = window.location.hostname.includes('cart.');
	const dispatch = useDispatch();

	const [registerData, setRegisterData] = useState<RegisterRequest>({
		username: '',
		email: '',
		password: '',
		password2: '',
		isConditionsAccepted: false,
		isCartDomain: isCartDomain
	});
	const [register, { isLoading }] = useRegisterMutation();
	const [isPasswordVisible, setIsPasswordVisible] = useState(false);
	const [isRepeatPasswordVisible, setIsRepeatPasswordVisible] = useState(false);
	const [isPasswordPopupVisible, setIsPasswordPopupVisible] = useState(false);
	const [popupStyle, setPopupStyle] = useState({ top: 0, left: 0 });
	const [googleSignupRequest, { isLoading: isGoogleSignupLoading }] = useGoogleSignupMutation();

	const registerInputRef = useRef<HTMLInputElement>(null);

	const navigate = useNavigate();

	const handleRegister = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		try {
			const response = await register(registerData).unwrap();

			if (response) {
				resetRegisterData();
				toast.success(response.message);
				dispatch(setEmail(registerData.email));
				navigate('/auth/email-verification');
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				if (error.data.validationErrors) {
					error.data.validationErrors.forEach((validationError) => {
						const constraints = validationError.constraints;
						const messages = Object.values(constraints).join('. ');
						toast.error(messages);
					});
				} else {
					toast.error(error.data.message);
				}
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	const resetRegisterData = () => {
		setRegisterData({
			username: '',
			email: '',
			password: '',
			password2: '',
			isConditionsAccepted: false
		});
	};

	const handleFocus = () => {
		setIsPasswordPopupVisible(true);
		if (registerInputRef.current) {
			const rect = registerInputRef.current.getBoundingClientRect();
			setPopupStyle({
				top: rect.top + window.scrollY,
				left: rect.right + window.scrollX
			});
		}
	};

	const handleBlur = () => {
		setIsPasswordPopupVisible(false);
	};

	useEffect(() => {
		const handleResize = () => {
			if (registerInputRef.current) {
				const rect = registerInputRef.current.getBoundingClientRect();
				setPopupStyle({
					top: rect.top + window.scrollY,
					left: rect.right + window.scrollX
				});
			}
		};
		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	function isCustomErrorResponse(error: unknown): error is CustomErrorResponse {
		return (
			typeof error === 'object' &&
			error !== null &&
			'data' in error &&
			typeof (error as CustomErrorResponse).data.message === 'string'
		);
	}

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target;
		setRegisterData((prevState) => ({
			...prevState,
			[name]: value
		}));
	};

	const handleSignupSuccess = async (response: any) => {
		try {
			const res = await googleSignupRequest({
				token: response.credential
			}).unwrap();
			if (res) {
				toast.success(res.message);
				dispatch(setEmail(res.email));
				navigate('/auth/login');
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	return (
		<div className="relative flex min-h-screen flex-col items-center justify-center">
			<Helmet>
				<title>Life on Track - Signup</title>
				<meta name="description" content="Signup to Life on Track" />
			</Helmet>
			<div className="absolute top-0 left-0 w-full flex justify-start p-2 sm:p-4 items-center gap-2">
				<img src={Logo} alt="logo" className="w-10 h-10" />
				{isCartDomain ? (
					<a href="https://lifeontrack.co" className="text-lt-beige dark:text-white font-extrabold text-2xl">
						Life on Track
					</a>
				) : (
					<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-2xl">
						Life on Track
					</Link>
				)}
			</div>

			<div className="flex flex-col items-center py-6 sm:py-10 gap-2 sm:gap-4 w-full bg-white dark:bg-lt-dark-popupBackground max-w-[700px] sm:min-h-[725px] border border-lt-beige dark:border-lt-dark-primary-border rounded-md sm:rounded-lg sm:shadow-xl">
				<h2 className="font-bold text-4xl sm:text-5xl mb-4">Create account</h2>
				<GoogleLogin
					locale="en"
					text="signup_with"
					size="large"
					onSuccess={handleSignupSuccess}
					onError={() => {
						console.log('Login Failed');
					}}
				/>
				<div className="flex items-center justify-center my-4 w-full">
					<div className="w-1/4 h-px bg-lt-beige dark:bg-lt-dark-primary-border"></div>
					<span className="mx-4 text-gray-500 font-medium">OR</span>
					<div className="w-1/4 h-px bg-lt-beige dark:bg-lt-dark-primary-border"></div>
				</div>

				<form onSubmit={handleRegister} className=" flex flex-col justify-center items-center gap-4 w-full max-w-xs">
					<Input
						disabled={isLoading}
						type="text"
						placeholder="Username"
						name="username"
						required
						value={registerData.username}
						onChange={handleChange}
					/>
					<Input
						disabled={isLoading}
						type="email"
						placeholder="Email"
						name="email"
						required
						value={registerData.email}
						onChange={handleChange}
					/>
					<Input
						disabled={isLoading}
						onFocus={handleFocus}
						onBlur={handleBlur}
						ref={registerInputRef}
						type={isPasswordVisible ? 'text' : 'password'}
						value={registerData.password}
						onChange={handleChange}
						placeholder="Password"
						name="password"
						required
						icon={
							isPasswordVisible ? (
								<IconEyeOff onClick={() => setIsPasswordVisible(false)} className="h-5 w-5 cursor-pointer" />
							) : (
								<IconEye onClick={() => setIsPasswordVisible(true)} className="h-5 w-5 cursor-pointer" />
							)
						}
					/>
					{isPasswordPopupVisible && (
						<div
							style={{
								position: 'absolute',
								...popupStyle
							}}
						>
							<PasswordPopup password={registerData.password} />
						</div>
					)}
					<Input
						disabled={isLoading}
						type={isRepeatPasswordVisible ? 'text' : 'password'}
						value={registerData.password2}
						onChange={handleChange}
						placeholder="Confirm Password"
						name="password2"
						required
						icon={
							isRepeatPasswordVisible ? (
								<IconEyeOff onClick={() => setIsRepeatPasswordVisible(false)} className="h-5 w-5 cursor-pointer" />
							) : (
								<IconEye onClick={() => setIsRepeatPasswordVisible(true)} className="h-5 w-5 cursor-pointer" />
							)
						}
					/>
					<div className="relative w-full max-w-xs flex">
						<input
							disabled={isLoading}
							type="checkbox"
							checked={registerData.isConditionsAccepted}
							onChange={() => {
								setRegisterData((prevState) => ({
									...prevState,
									isConditionsAccepted: !prevState.isConditionsAccepted
								}));
							}}
							className="w-4 h-4 sm:w-6 sm:h-6 rounded-sm sm:rounded-md checkbox [--chkbg:theme(colors.lt-beige)] [--chkfg:#FAEDCD] dark:[--chkbg:theme(colors.lt-dark-primary-bg)] dark:[--chkfg:#FFFFFF] checked:border-none"
						/>

						<span className="text-xs ml-2">
							I have read and accept the{' '}
							<Link to="/en/terms-of-service" className="underline text-lt-beige">
								Terms of Service
							</Link>
							{'  '}
							and{'  '}
							<Link to="/en/privacy-policy" className="underline text-lt-beige">
								Privacy Policy
							</Link>
						</span>
					</div>

					{isLoading || isGoogleSignupLoading ? (
						<button
							disabled
							className="btn btn-square w-full mt-4 bg-lt-light-primary-disabled-bg dark:bg-lt-dark-primary-disabled-bg"
						>
							<span className="loading loading-spinner"></span>
						</button>
					) : (
						<Button
							variant="btn-primary-light"
							className="w-full mt-4"
							type="submit"
							disabled={
								!registerData.username ||
								!registerData.email ||
								!registerData.password ||
								!registerData.password2 ||
								!registerData.isConditionsAccepted ||
								isLoading ||
								isGoogleSignupLoading
							}
						>
							Signup
						</Button>
					)}
				</form>

				<h6>
					Already have an account?{' '}
					<Link to={'/auth/login'} className="underline text-lt-beige">
						Login
					</Link>
				</h6>
				{/* <h6>
					<Link to="/auth/whitelist" className="underline">
						Please enter your email for the whitelist request.
					</Link>
				</h6> */}
			</div>
		</div>
	);
}
