import { IconArrowLeft } from '@tabler/icons-react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Transition } from '@headlessui/react';
import { Helmet } from 'react-helmet';

export default function FAQs() {
	const faqData = [
		{
			question: 'What happens to my tables if I switch back to the free version?',
			answer:
				'After your 7-day free trial ends, all data from your tables is safely stored and your tables will be frozen. When you subscribe again, you can continue using your tables from where you left off.'
		},
		{
			question: 'Can everyone see the tables I create?',
			answer:
				'No, your tables are only visible to people who follow you. However, if you prefer to keep them private, you can set your table to private so that only you can see it.'
		},
		{
			question: 'What can I do with the in-app money?',
			answer: 'You can use the coins you earn in the app to purchase various stickers from the shop.'
		},
		{
			question: 'What can I do with the stickers I purchase?',
			answer:
				'You can use the stickers you purchase to decorate your virtual journal on the My Journal page, making your tables and journal more visually appealing.'
		},
		{
			question: 'How does the virtual journal work?',
			answer:
				'The virtual journal is where you can display your tables and stickers. It’s a creative space where you can organize your activity tracking in a visually appealing way. Friends who follow you can view your journal.'
		},
		{
			question: 'What are achievements and leaderboards?',
			answer:
				'Achievements are milestones you can unlock as you use the app, such as logging activities consistently or reaching specific goals. Leaderboards show how you rank compared to your friends and other users in different activities.'
		},
		{
			question: 'What can I see from the people I follow?',
			answer:
				'You can view the achievements, stickers, streak count, recent activities, and virtual journals of the users you follow.'
		}
	];
	const [openIndex, setOpenIndex] = useState<number | null>(null);

	const handleClick = (index: number) => {
		setOpenIndex(openIndex === index ? null : index);
	};
	return (
		<div className="relative flex flex-col items-center pt-20">
			<Helmet>
				<title>FAQs | Life on Track</title>
				<meta
					name="description"
					content="Find answers to common questions, troubleshooting tips, and more. If you have more detailed questions, please visit our FAQs."
				/>
			</Helmet>
			<Link to="/help" className="flex items-center  absolute left-0  top-0 text-3xl font-bold text-lt-beige">
				<IconArrowLeft className="w-16 h-16 " />
			</Link>
			<div className="h-screen overflow-y-auto flex flex-col items-center w-full pb-40 sm:pb-0">
				<header className="font-bold text-3xl sm:text-5xl mb-2">FAQs</header>
				<h2 className="text-gray-600 sm:text-xl dark:text-white">Answers to all the questions you got on your mind</h2>
				<div className="  mt-8 w-full md:w-3/4 base:w-1/2">
					{faqData.map((item, index) => (
						<div
							key={index}
							className="mb-4 border border-lt-beige rounded-md bg-[#FAEDCD]dark:bg-lt-dark-popupBackground "
						>
							<button
								onClick={() => handleClick(index)}
								className="w-full text-left py-3 px-4 bg-[#FAEDCD] dark:bg-lt-dark-popupBackground hover:bg-lt-beige focus:outline-none rounded-md"
							>
								<div className="flex justify-between items-center ">
									<span className="font-bold text-[#8E4600] dark:text-white text-lg sm:text-xl">{item.question}</span>
									<span>{openIndex === index ? '-' : '+'}</span>
								</div>
							</button>
							<Transition
								show={openIndex === index}
								enter="transition ease-out duration-300"
								enterFrom="transform opacity-0 scale-95"
								enterTo="transform opacity-100 scale-100"
								leave="transition ease-in duration-200"
								leaveFrom="transform opacity-100 scale-100"
								leaveTo="transform opacity-0 scale-95"
							>
								<div className="px-4 pb-3 text-[#8E4600] bg-[#FAEDCD] dark:text-white dark:bg-lt-dark-popupBackground rounded-md text-start">
									{item.answer}
								</div>
							</Transition>
						</div>
					))}
				</div>
			</div>
		</div>
	);
}
