import { IconPigMoney, IconTableHeart } from '@tabler/icons-react';
import { Link } from 'react-router-dom';

export default function Subscription({ id }: { id: string }) {
	return (
		<dialog id={id} className="modal">
			<div className="modal-box p-0 border-2 border-lt-beige bg-lt-cream dark:bg-lt-dark-secondary-bg dark:border-lt-dark-popupBorder md:min-w-[900px] ">
				<form method="dialog">
					<button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
				</form>

				<div className="modal-action mt-0">
					<form method="dialog" className="flex flex-col md:flex-row w-full">
						<div className="bg-lt-lightCream dark:bg-lt-dark-primary-bg p-6 md:w-1/2">
							<h2 className="font-bold text-xl">Upgrade to Full Access</h2>
							<div className="flex flex-col gap-4 mt-5">
								<div className="flex items-start gap-2">
									<IconTableHeart className="min-w-5 min-h-5" />
									<div>
										<h4 className="text-base font-semibold">Unlimited Tables</h4>
										<h5 className="text-xs">
											You can create unlimited tables and store your data without any limitation.
										</h5>
									</div>
								</div>
								<div className="flex items-start gap-2">
									<IconPigMoney className="min-w-5 min-h-5" />
									<div>
										<h4 className="text-base font-semibold">Earn More Coins</h4>
										<h5 className="text-xs">
											As your streak increases, earn more coins. Unlock new stickers with the coins you earn and
											decorate your virtual journal!
										</h5>
									</div>
								</div>
							</div>

							{/* <img src={PaymentMethods} alt="Payment Methods" className="w-full" /> */}
						</div>
						<div className="bg-lt-cream dark:bg-lt-dark-secondary-bg flex flex-col justify-start p-6 min-h-[400px]">
							<h2 className="font-bold text-xl">Choose your plan</h2>
							<h4 className="text-sm text-lt-dark-inputPlaceholder">
								You can cancel your subscription any time. <br />
								No hidden fees.
							</h4>
							<div className="mt-5 flex flex-col justify-between h-full">
								<div className="form-control flex flex-col h-2/3 justify-around">
									<label className="label cursor-pointer flex justify-start gap-2">
										<input type="radio" name="plan" value="monthly" className="radio checked:bg-lt-beige" />
										{/* <input
											type="radio"
											name="plan"
											value="monthly"
											className="radio checked:bg-lt-beige"
											checked={selectedPlan === 'monthly'}
											onChange={() => setSelectedPlan('monthly')}
										/> */}
										<span className="label-text font-bold">Monthly - $3.99/mo</span>
									</label>
									<label className="label cursor-pointer flex justify-start gap-2">
										<input type="radio" name="plan" value="annual" className="radio checked:bg-lt-beige" />
										<div className="flex flex-col">
											<span className="label-text font-bold">
												Annual - <span className="line-through decoration-2 font-normal">$3.99/mo</span> $2.66/mo
											</span>
											<span className="text-xs text-lt-dark-inputPlaceholder">$32 billed every year</span>
										</div>
									</label>
									<label className="label cursor-pointer flex justify-start gap-2">
										<input type="radio" name="plan" value="life-time" className="radio checked:bg-lt-beige" />
										<div className="flex flex-col">
											<span className="label-text font-bold">Life-time - $96</span>
											<span className="text-xs text-lt-dark-inputPlaceholder">Billed once</span>
										</div>
									</label>
								</div>
								<div className="flex flex-col items-start gap-2">
									<Link
										to="/payment-page"
										className="flex justify-center bg-lt-light-primary-bg text-lt-white border-lt-light-primary-border hover:bg-lt-light-primary-hover-bg px-4 py-2 rounded-lg w-full dark:bg-lt-dark-primary-bg dark:text-lt-dark-primary-text dark:border-lt-dark-primary-border dark:hover:bg-lt-dark-primary-hover-bg dark:hover:text-lt-dark-primary-text"
									>
										Upgrade Now
									</Link>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</dialog>
	);
}
