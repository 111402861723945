import { useEffect, useRef, useState } from 'react';
import {
	FollowRequestStatus,
	GetFollowRequestsResponse,
	IFollowRequest,
	useLazyGetFollowRequestsQuery,
	useRespondToFollowRequestMutation
} from '../../../services/api/profile';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { achievementEarnedSuccess, updateMyFollowersSuccess } from '../../../states/profile/profileSlice';
import { IAchievement } from '../../../types/achievement.types';
import AchievementToast from '../achievements/AchievementToast';
import { Link } from 'react-router-dom';
import noProfileImg from '../../../assets/no-profile-picture.svg';
import { IconCaretDown, IconChevronLeft, IconChevronRight, IconCircleCheck, IconXboxX } from '@tabler/icons-react';
import noFriends from '/src/assets/emptyStates/noFriends.webp';

export default function FollowRequests() {
	const dispatch = useDispatch();
	const [isFollowRequestLoading, setIsFollowRequestLoading] = useState(false);
	const [getFollowRequest] = useLazyGetFollowRequestsQuery();
	const [page, setPage] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [followRequests, setFollowRequests] = useState<GetFollowRequestsResponse>();
	const [selectedFriendId, setSelectedFriendId] = useState('');
	const [respondFollowRequestIsLoading, setRespondFollowRequestIsLoading] = useState(false);
	const [respondToFollowRequest] = useRespondToFollowRequestMutation();

	useEffect(() => {
		const getFollowRequests = async () => {
			setIsFollowRequestLoading(true);
			try {
				const response = await getFollowRequest({
					page: page,
					limit: 10
				}).unwrap();
				if (response) {
					setFollowRequests(response);
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please try again later.');
				}
			} finally {
				setIsFollowRequestLoading(false);
			}
		};
		getFollowRequests();
	}, [page, followRequests, getFollowRequest]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent | TouchEvent | PointerEvent | KeyboardEvent | FocusEvent | Event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleDropdownToggle = () => {
		setIsOpen((prev) => !prev);
	};

	const handlePageSelect = (page: number) => {
		setPage(page);
		setIsOpen(false);
	};

	const handleRespondRequest = async (requestId: string, senderId: string, status: FollowRequestStatus) => {
		try {
			setSelectedFriendId(senderId);
			setRespondFollowRequestIsLoading(true);
			const body = {
				requestId,
				status
			};
			const response = await respondToFollowRequest(body).unwrap();
			if (response) {
				toast.success(response.message);
				setFollowRequests((prev) => {
					if (!prev) return undefined;
					const updatedData = prev.data.filter((request) => request._id !== requestId);
					return {
						...prev,
						data: updatedData,
						pagination: {
							...prev.pagination,
							totalFollowRequests: prev.pagination.totalFollowRequests - 1
						}
					};
				});
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				if (status === FollowRequestStatus.ACCEPTED) {
					dispatch(updateMyFollowersSuccess(response.data.sender._id));
				}
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		} finally {
			setRespondFollowRequestIsLoading(false);
			setSelectedFriendId('');
		}
	};

	const closeFollowRequestModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		(document.getElementById('follow_request_modal') as HTMLDialogElement)?.close();
	};

	return (
		<div className="flex items-end">
			<dialog id="follow_request_modal" className="modal">
				<div className="p-0 modal-box max-w-2xl bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground border border-lt-light-popupBorder dark:border-lt-dark-popupBorder relative">
					<div className="sticky top-0 bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground z-10 p-4">
						<form method="dialog">
							<button
								onClick={(e) => closeFollowRequestModal(e)}
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							>
								✕
							</button>
						</form>
						<h3 className="font-bold text-3xl">Follow Requests</h3>
					</div>
					<div className="h-[600px] flex flex-col">
						<div className="flex-1 overflow-y-auto p-4">
							{isFollowRequestLoading ? (
								<div className="w-full min-h-[288px] h-full flex justify-center items-center">
									<span className="loading loading-spinner loading-xs"></span>
								</div>
							) : (
								<>
									{followRequests && followRequests.data.length > 0 ? (
										<ul className="flex flex-col gap-3">
											{followRequests.data.map((request: IFollowRequest, _index) => (
												<li key={request._id} className="relative pb-4">
													{respondFollowRequestIsLoading && request.sender._id === selectedFriendId ? (
														<div className="absolute inset-0 bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground bg-opacity-50 flex items-center justify-center">
															<span className="loading loading-spinner loading-xs"></span>
														</div>
													) : (
														<>
															<div className="flex items-center justify-between">
																<Link to={`/dashboard/profile/${request.sender._id}`}>
																	<div className="flex items-center">
																		<img
																			src={request.sender.profileImgUrl ?? noProfileImg}
																			alt="profile"
																			className="w-6 h-6 sm:w-10 sm:h-10 rounded-full object-cover"
																		/>
																		<span className="ml-2 font-semibold sm:text-xl">{request.sender.username}</span>
																	</div>
																</Link>
																<div className="flex items-center sm:gap-2">
																	<button
																		disabled={respondFollowRequestIsLoading}
																		onClick={() =>
																			handleRespondRequest(
																				request._id,
																				request.sender._id,
																				FollowRequestStatus.ACCEPTED
																			)
																		}
																		className="flex items-center p-1 sm:p-2 hover:bg-lt-light-secondary-hover-bg dark:hover:bg-lt-dark-secondary-bg rounded-md"
																	>
																		<IconCircleCheck className="w-6 h-6 text-green-500" />
																		<h5 className=" ml-1 text-sm sm:text-base font-semibold">Accept</h5>
																	</button>
																	<button
																		disabled={respondFollowRequestIsLoading}
																		onClick={() =>
																			handleRespondRequest(
																				request._id,
																				request.sender._id,
																				FollowRequestStatus.DECLINED
																			)
																		}
																		className="flex items-center p-1 sm:p-2 hover:bg-lt-light-secondary-hover-bg dark:hover:bg-lt-dark-secondary-bg rounded-md"
																	>
																		<IconXboxX className="w-6 h-6 text-red-500" />
																		<h5 className=" ml-1 text-sm sm:text-base font-semibold">Decline</h5>
																	</button>
																</div>
															</div>
															<div className="absolute bottom-0 left-0 right-0 h-px bg-lt-beige dark:bg-[#6f6b6b]"></div>
														</>
													)}
												</li>
											))}
										</ul>
									) : (
										<div className="min-h-[500px] w-full h-full flex flex-col gap-6 justify-center items-center">
											<img className="w-[200px] h-[135px]" src={noFriends} alt="No follow request" />
											<span>No follow requests.</span>
										</div>
									)}
								</>
							)}
						</div>
						<div className="bg-lt-lightCream dark:bg-lt-dark-secondary-bg flex justify-between border-t border-lt-beige dark:border-lt-dark-popupBorder">
							<button
								disabled={isFollowRequestLoading || page === 1 || followRequests?.pagination.totalFollowRequests === 0}
								onClick={() => {
									if (page === 1) return;
									setPage(page - 1);
								}}
								className="btn bg-transparent border-y-0 border-l-0 rounded-none border-r border-lt-beige dark:border-lt-dark-popupBorder shadow-none h-[58px]"
							>
								<IconChevronLeft />
							</button>
							<div ref={dropdownRef} className="relative">
								<button
									className="btn bg-transparent shadow-none border-y-0 rounded-none border-x border-lt-beige dark:border-lt-dark-popupBorder h-[58px]"
									onClick={handleDropdownToggle}
								>
									{followRequests?.pagination.page} of {followRequests?.pagination.totalFollowRequests}
									<IconCaretDown className="w-4 h-4" />
								</button>
								{isOpen && (
									<ul className="menu dropdown-content rounded-md z-[1] bg-lt-lightCream dark:bg-lt-dark-popupBackground w-28 p-0 shadow absolute bottom-full left-1/2 -translate-x-1/2 max-h-60 overflow-y-auto border border-lt-beige dark:border-lt-dark-popupBorder">
										{Array(followRequests?.pagination.totalFollowRequests)
											.fill(null)
											.map((_, i, arr) => (
												<li key={i}>
													<button
														disabled={isFollowRequestLoading}
														className={`h-10 rounded-sm border-x-0 border-t-0 w-full ${i !== arr.length - 1 ? 'border-b-2 border-lt-beige dark:border-lt-dark-popupBorder' : ''}`}
														onClick={() => handlePageSelect(i + 1)}
														value={i + 1}
													>
														{i + 1}
													</button>
												</li>
											))}
									</ul>
								)}
							</div>
							<button
								disabled={
									isFollowRequestLoading ||
									page === followRequests?.pagination.totalFollowRequests ||
									followRequests?.pagination.totalFollowRequests === 0
								}
								onClick={() => {
									if (page === 10) return;
									setPage(page + 1);
									followRequests?.pagination.totalFollowRequests;
								}}
								className="btn bg-transparent border-y-0 border-r-0 rounded-none border-l border-lt-beige dark:border-lt-dark-popupBorder shadow-none h-[58px]"
							>
								<IconChevronRight />
							</button>
						</div>
					</div>
				</div>
			</dialog>
			<div>
				<span className="flex gap-1">
					<strong>{followRequests?.pagination.totalFollowRequests}</strong>{' '}
					<button
						disabled={followRequests?.data.length === 0}
						onClick={() => (document.getElementById('follow_request_modal') as HTMLDialogElement)?.showModal()}
						className={`text-lt-beige dark:text-lt-dark-popupText font-semibold text-xs sm:text-base ${followRequests?.data.length === 0 ? '' : 'dark:hover:text-lt-beige transition-colors duration-300'}`}
					>
						Follow Requests
					</button>
				</span>
			</div>
		</div>
	);
}
