import { IconArrowLeft } from '@tabler/icons-react';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Link as ReactLink, Element, scroller } from 'react-scroll';
import createTableDetails from '/src/assets/articles/create-table-details.svg';
import createTable from '/src/assets/articles/create-table.svg';
import tableDetails from '/src/assets/articles/table-details.svg';

export default function LifeTracker() {
	const [activeContent, setActiveContent] = useState('');

	useEffect(() => {
		const sections = ['first-table', 'table-details', 'profile', 'social', 'virtual-journal', 'achievements-shop'];

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setActiveContent(entry.target.id);
					}
				});
			},
			{ threshold: 0.6 }
		);

		sections.forEach((section) => {
			const element = document.getElementById(section);
			if (element) observer.observe(element);
		});

		return () => {
			sections.forEach((section) => {
				const element = document.getElementById(section);
				if (element) observer.unobserve(element);
			});
		};
	}, []);

	const handleLinkClick = (section: string) => {
		setActiveContent(section);
		const contentContainer = document.querySelector('.content-container');
		if (contentContainer) {
			scroller.scrollTo(section, {
				duration: 500,
				smooth: true,
				containerId: 'content-container'
			});
		}
	};

	return (
		<div className="relative min-h-screen">
			<Helmet>
				<title>Getting Started With Life on Track | Life on Track</title>
				<meta
					name="description"
					content="Welcome to Life on Track! If you're new to our app, this guide will help you get started with tracking your habits, routines, and more."
				/>
			</Helmet>
			<Link to="/help/getting-started" className="flex items-center sticky top-0 text-3xl font-bold text-lt-beige">
				<IconArrowLeft className="w-16 h-16" />
			</Link>
			<div className="flex justify-between p-5 sm:p-20 sm:pt-32 gap-5 h-[calc(100vh-64px)] overflow-hidden">
				<div className="hidden sm:block w-1/6 sticky top-10 self-start">
					<h1 className="text-xl font-bold text-lt-beige mb-2">Table of Contents</h1>
					<div className="border border-lt-beige rounded-xl">
						<ul className="flex text-xs flex-col divide-y divide-lt-beige">
							{['first-table', 'table-details', 'profile', 'social', 'virtual-journal', 'achievements-shop'].map(
								(section, index) => (
									<li key={index} className="p-2">
										<button
											onClick={() => handleLinkClick(section)}
											className={`cursor-pointer w-full text-left ${
												activeContent === section ? 'text-lt-beige' : 'text-lt-darkCream'
											}`}
											aria-label={`Scroll to ${section.replace('-', ' ')}`}
										>
											{section.replace('-', ' ')}
										</button>
									</li>
								)
							)}
						</ul>
					</div>
				</div>

				<div
					id="content-container"
					className="content-container w-full sm:w-4/6 flex flex-col items-start gap-5 divide-y divide-lt-softGreen overflow-y-auto pb-40 sm:pb-0"
				>
					<div>
						<h1 className="font-bold text-3xl">Getting Started With Life on Track</h1>
						<h3 className="text-md mt-4 text-gray-600 dark:text-white">
							Welcome to Life on Track! If you're new to our app, this guide will help you get started with tracking
							your habits, routines, and more.
						</h3>
					</div>
					<div className="w-full pt-5">
						<Element name="first-table" className="flex flex-col gap-5">
							<h2 className="font-bold text-2xl" id="first-table">
								Create Your First Table
							</h2>
							<img className="w-full max-w-[600px]" src={createTable} alt="create table" />
							<p className="text-gray-600 dark:text-white">
								After creating your account on Life on Track, log in and go to the <strong>My Tables</strong> page,
								which opens by default. To create your first table, click the <strong>Create Table</strong> button in
								the top-right corner. On the next screen, you'll need to enter the essential information for your table.
							</p>
							<h3 className="font-bold text-xl">Table Properties</h3>
							<img className="w-full max-w-[600px]" src={createTableDetails} alt="create table details" />
							<div className="p-6 rounded-lg space-y-6">
								<div>
									<h3 className="text-lg font-semibold text-gray-700 dark:text-white">
										1. Table Title <span className="text-red-500">(Required)</span>
									</h3>
									<p className="text-gray-600 dark:text-white">
										The Table Title should clearly reflect the purpose of your table.
									</p>
								</div>

								<div>
									<h3 className="text-lg font-semibold text-gray-700 dark:text-white">2. Table Icon</h3>
									<p className="text-gray-600 dark:text-white">
										Use the button next to the title input to open the icon selection popup. Although optional, a chosen
										icon appears alongside the title for easy identification.
									</p>
								</div>

								<div>
									<h3 className="text-lg font-semibold text-gray-700 dark:text-white">
										3. Interval & Key Value <span className="text-red-500">(Required)</span>
									</h3>
									<p className="text-gray-600 dark:text-white">
										Selecting between Interval or Key Value is crucial for creating the table:
									</p>
									<ul className="ml-4 list-disc text-gray-600 dark:text-white">
										<li>
											<strong>Interval</strong>: Ideal for tracking ranges (e.g., water intake). Specify minimum and
											maximum values and assign colors to each range for a visual overview of your data.
										</li>
										<li>
											<strong>Key Value</strong>: Suitable for subjective data (e.g., mood tracking). Assign colors to
											specific values, like "Happy" or "Calm," making it simple to track daily states visually.
										</li>
									</ul>
								</div>

								<div>
									<h3 className="text-lg font-semibold text-gray-700 dark:text-white">4. Make It Public</h3>
									<p className="text-gray-600 dark:text-white">
										By default, tables are private. You can select the public option for any table, making it viewable
										in your virtual journal by friends. This setting can be updated later.
									</p>
								</div>

								<p className="text-gray-600 dark:text-white">
									Once done, click <strong>Create Table</strong> to finalize your setup.
								</p>
							</div>
						</Element>
					</div>
					<div id="table-details" className="w-full pt-5">
						<Element name="table-details">
							<h2 className="font-bold text-2xl">Table Details</h2>
							<img className="w-full max-w-[600px]" src={tableDetails} alt="create table details" />
							<div className="p-8 space-y-6">
								<p className="text-gray-600 dark:text-white">
									Access the details of any table by clicking on the three-dot menu at the top right of your tables on
									the My Tables page or by selecting the table title.
								</p>
								<div className="space-y-4">
									<h2 className="text-lg font-semibold text-gray-600 dark:text-white">1. Trash Bin</h2>
									<p className="text-gray-600 dark:text-white">
										Permanently delete your table. You can also quickly delete tables from the My Tables page via the
										three-dot menu.
									</p>
									<h2 className="text-lg font-semibold text-gray-600 dark:text-white">
										2. Intervals/Key Values and Clear Today's Selection
									</h2>
									<p className="text-gray-600 dark:text-white">
										Fill in your table by selecting the corresponding interval or key value for the day. Use the{' '}
										<span className="font-bold">Clear Today's Selection</span> button if you need to reset today's
										entry.
									</p>
									<h2 className="text-lg font-semibold text-gray-600 dark:text-white">3. Show More Details</h2>
									<p className="text-gray-600 dark:text-white">
										Access additional data insights related to your table with this button.
									</p>
									<h2 className="text-lg font-semibold text-gray-600 dark:text-white">4. Notes</h2>
									<p className="text-gray-600 dark:text-white">
										Use the Notes section to jot down any relevant information about your table. These notes are private
										and visible only to you.
									</p>
								</div>
							</div>
						</Element>
					</div>
					<div id="profile" className="w-full pt-5">
						<Element name="profile">
							<h2 className="font-bold text-2xl">Profile</h2>
							<p className="text-gray-600 dark:text-white mb-2">
								Manage personal actions like <strong>followers</strong>, <strong>following lists</strong>,{' '}
								<strong>change password</strong> and <strong>subscription details</strong> within the Profile section.
							</p>
							<p className="text-gray-600 dark:text-white mb-2">
								If you have a subscription, you can collect your daily streak rewards from here.
							</p>
							<p className="text-gray-600 dark:text-white">
								When visiting a friend's profile, you can view their <strong>virtual journal</strong> from this section.
							</p>
						</Element>
					</div>
					<div id="social" className="w-full pt-5">
						<Element name="social">
							<h2 className="font-bold text-2xl">Social</h2>
							<p className="text-gray-600 dark:text-white mb-2">
								In the <strong>Social</strong> section, you can view streak boards showcasing your friends' latest
								activities and track their progress.
							</p>
							<p className="text-gray-600 dark:text-white mb-2">
								You can also search for a specific user and follow them to view their journal and recent activities.
							</p>
						</Element>
					</div>
					<div id="virtual-journal" className="w-full pt-5">
						<Element name="virtual-journal">
							<h2 className="font-bold text-2xl">Virtual Journal</h2>
							<p className="text-gray-600 dark:text-white mb-2">
								Your <strong>Virtual Journal</strong> is a customized book filled with your selected (public) tables and
								decorated with stickers. Followers can view your journal from your profile, and you can also view the
								journals of users you follow from their profiles.
							</p>
						</Element>
					</div>
					<div id="achievements-shop" className="w-full pt-5">
						<Element name="achievements-shop">
							<h2 className="font-bold text-2xl">Achievements & Shop</h2>
							<p className="text-gray-600 dark:text-white mb-2">
								Earn in-app coins by completing <strong>Achievements</strong> (available to all users) and use them to
								purchase stickers in the shop. Once purchased, you can place stickers on any page of your journal to
								enhance its appearance, making your Virtual Journal truly unique.
							</p>
						</Element>
					</div>
				</div>
			</div>
		</div>
	);
}
