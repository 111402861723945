import { IconArticle } from '@tabler/icons-react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import './help.css';
import Logo from '/src/assets/logo.svg';

export default function Help() {
	const SubjectHeadings = [
		{
			to: '/help/getting-started',
			title: 'Getting Started',
			description: 'If you are new to Life on Track, this is the best place to start.',
			icon: 'ti ti-player-play',
			articleCount: 1
		},
		{
			to: '/help/faqs',
			title: 'FAQs',
			description: 'Find answers to common questions, troubleshooting tips, and more.',
			icon: 'ti ti-question-mark',
			articleCount: 7
		}
	];

	return (
		<div className="relative">
			<Helmet>
				<title>Support Documentation | Life on Track</title>
				<meta
					name="description"
					content="Questions? We have answers. Check out our support documentation to find solutions to common problems, learn how to use Life on Track, and get the most out of your experience."
				/>
			</Helmet>
			<header className="sticky top-0 z-50 w-full h-[70px] px-4 md:px-12 flex items-center justify-between border-b  bg-lt-lightCream dark:bg-lt-dark-secondary-bg">
				<div className="flex justify-start items-center md:gap-2 w-1/2">
					<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
					<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-sm sm:text-2xl">
						Life on Track
					</Link>
				</div>
				<div className="flex justify-between base:justify-center base:gap-10 w-1/2">
					<Link
						to="/about-us"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						About Us
					</Link>
					<Link
						to="/blogs"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Blogs
					</Link>
					<Link
						to="/"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Home
					</Link>
				</div>
			</header>
			<div className="h-screen overflow-y-auto pb-40 sm:pb-0">
				<div className="help flex flex-col items-center px-10 py-20 sm:py-40 gap-4">
					<h1 className="font-bold text-2xl sm:text-4xl text-center dark:text-white">Support Documentation</h1>
					<h2 className="text-lg sm:text-xl text-center">
						Questions? We have answers. Check out our support documentation to find solutions to common problems, learn
						how to use Life on Track, and get the most out of your experience.
					</h2>
				</div>

				<div className="flex flex-col gap-5 p-10">
					<h3 className="text-2xl font-semibold">Explore all topics</h3>
					<div className="grid grid-cols-1 gap-5 sm:grid-cols-2 md:grid-cols-3 sm:gap-10">
						{SubjectHeadings.map((subject) => (
							<Link
								to={subject.to}
								className="border border-lt-beige bg-white dark:bg-lt-dark-primary-bg shadow-lg rounded-lg gap-4 py-10 px-5 flex flex-col items-center hover:bg-lt-lightCream dark:hover:bg-lt-dark-primary-hover-bg "
								key={subject.title}
							>
								<i className={`${subject.icon} text-5xl text-lt-beige`}></i>

								<h3 className="font-semibold text-2xl">{subject.title} </h3>
								<p className="flex items-center text-lt-dark-primary-disabled-text text-sm gap-1">
									<IconArticle className="text-lt-dark-primary-disabled-text w-5 h-5" />
									{subject.articleCount} Article
								</p>

								<h4 className="text-center">{subject.description}</h4>
							</Link>
						))}
					</div>
				</div>
				<div className="flex flex-col items-center py-10 mb-20 text-center">
					<h3 className="text-xl font-bold mb-2">Can't find what you're looking for?</h3>
					<p>
						For any queries, suggestions, or feedback, please{' '}
						<a href="mailto:contact@lifeontrack.co" className="text-lt-beige font-bold underline">
							contact
						</a>{' '}
						us
					</p>
				</div>
			</div>
		</div>
	);
}
