const categorizedIcons = {
	animals: ['ti ti-cat', 'ti ti-dog', 'ti ti-fish'],
	business: [
		'ti ti-award',
		'ti ti-badge',
		'ti ti-briefcase',
		'ti ti-calendar',
		'ti ti-chart-bar',
		'ti ti-chart-line',
		'ti ti-chart-pie',
		'ti ti-clipboard',
		'ti ti-credit-card',
		'ti ti-file',
		'ti ti-folder',
		'ti ti-notebook',
		'ti ti-paperclip',
		'ti ti-pencil',
		'ti ti-pig-money',
		'ti ti-target-arrow',
		'ti ti-wallet'
	],
	food: [
		'ti ti-apple',
		'ti ti-beer',
		'ti ti-beer-off',
		'ti ti-bottle',
		'ti ti-bread',
		'ti ti-burger',
		'ti ti-cake',
		'ti ti-coffee',
		'ti ti-coffee-off',
		'ti ti-glass-cocktail',
		'ti ti-glass-full',
		'ti ti-glass-off',
		'ti ti-ice-cream',
		'ti ti-meat',
		'ti ti-pizza',
		'ti ti-salad',
		'ti ti-soup'
	],
	health: [
		'ti ti-building-hospital',
		'ti ti-first-aid-kit',
		'ti ti-heart',
		'ti ti-hospital-circle',
		'ti ti-medicine-syrup',
		'ti ti-pill',
		'ti ti-stethoscope',
		'ti ti-vaccine',
		'ti ti-vaccine-bottle'
	],
	moods: [
		'ti ti-mood-cog',
		'ti ti-mood-cry',
		'ti ti-mood-edit',
		'ti ti-mood-happy',
		'ti ti-mood-look-down',
		'ti ti-mood-look-up',
		'ti ti-mood-puzzled',
		'ti ti-mood-sick',
		'ti ti-mood-smile',
		'ti ti-mood-tongue'
	],
	others: [
		'ti ti-alarm',
		'ti ti-anchor',
		'ti ti-bed',
		'ti ti-bell',
		'ti ti-bolt',
		'ti ti-bong',
		'ti ti-bong-off',
		'ti ti-book',
		'ti ti-books',
		'ti ti-brain',
		'ti ti-brush',
		'ti ti-bulb',
		'ti ti-calendar',
		'ti ti-chef-hat',
		'ti ti-cloud',
		'ti ti-currency-dollar',
		'ti ti-download',
		'ti ti-droplet',
		'ti ti-droplet-half-2',
		'ti ti-empathize',
		'ti ti-eraser',
		'ti ti-flag',
		'ti ti-flame',
		'ti ti-flower',
		'ti ti-gift',
		'ti ti-hammer',
		'ti ti-home',
		'ti ti-hourglass',
		'ti ti-key',
		'ti ti-leaf',
		'ti ti-list',
		'ti ti-lock',
		'ti ti-medal',
		'ti ti-moon',
		'ti ti-music',
		'ti ti-package',
		'ti ti-photo',
		'ti ti-rocket',
		'ti ti-settings',
		'ti ti-shield',
		'ti ti-smoking-no',
		'ti ti-star',
		'ti ti-stopwatch',
		'ti ti-tag',
		'ti ti-trash',
		'ti ti-umbrella',
		'ti ti-upload',
		'ti ti-user',
		'ti ti-user-screen',
		'ti ti-video',
		'ti ti-vocabulary',
		'ti ti-windmill',
		'ti ti-zzz'
	],
	sports: [
		'ti ti-barbell',
		'ti ti-bike',
		'ti ti-run',
		'ti ti-stretching',
		'ti ti-swimming',
		'ti ti-trophy',
		'ti ti-walk'
	],
	technology: [
		'ti ti-camera',
		'ti ti-device-ipad',
		'ti ti-device-laptop',
		'ti ti-device-mobile',
		'ti ti-device-tv',
		'ti ti-device-watch',
		'ti ti-devices-pc',
		'ti ti-headphones',
		'ti ti-microphone',
		'ti ti-phone',
		'ti ti-telescope'
	],
	travel: [
		'ti ti-beach',
		'ti ti-camper',
		'ti ti-car',
		'ti ti-compass',
		'ti ti-globe',
		'ti ti-map',
		'ti ti-map-pin',
		'ti ti-mountain',
		'ti ti-plane',
		'ti ti-ship',
		'ti ti-tent',
		'ti ti-train',
		'ti ti-truck'
	],
	weather: [
		'ti ti-cloud',
		'ti ti-cloud-bolt',
		'ti ti-cloud-rain',
		'ti ti-rainbow',
		'ti ti-snowflake',
		'ti ti-sun',
		'ti ti-wind'
	]
};

export default categorizedIcons;
