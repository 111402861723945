import { Link } from 'react-router-dom';
import { IconCoins, IconDots, IconLogout, IconMoon, IconSun } from '@tabler/icons-react';
import { useTheme } from '../../context/ThemeContext';
import { RootState } from '../../states/store';
import Logo from '../../assets/logo.svg';
import { useLogout } from '../../hooks/useLogout';
import CustomModule from '../customModule/customModule';
import { useSelector } from 'react-redux';

export default function TopMenu() {
	const { profile } = useSelector((state: RootState) => state.profile);
	const { theme, toggleTheme } = useTheme();
	const { isLoggingOut } = useSelector((state: RootState) => state.auth);
	const logout = useLogout();

	const openAYSModule = () => {
		(document.getElementById('topmenu-logout-ays-modal') as HTMLDialogElement).showModal();
	};

	return (
		<div className="fixed top-0 left-0 right-0 navbar bg-lt-cream dark:bg-lt-dark-secondary-bg z-50">
			<CustomModule
				id="topmenu-logout-ays-modal"
				title="Logout"
				description="Are you sure you want to logout?"
				buttonText="Logout"
				onButtonClick={() => {
					logout();
				}}
				isLoading={isLoggingOut}
			/>
			<div className="flex-1">
				<img className="w-10 h-10 " src={Logo} alt="logo" />
				<Link to="/" className="font-bold text-lg text-lt-beige  dark:text-white">
					Life on Track
				</Link>
			</div>

			<div className="flex-none gap-2">
				<div className="flex">
					<IconCoins className="text-lt-beige" />
					<h4 className="text-lt-beige dark:text-white font-semibold">{profile.appMoney}</h4>
				</div>
				<div className="dropdown dropdown-end">
					<div tabIndex={0} role="button" className="btn btn-ghost btn-circle avatar">
						<IconDots className="text-lt-beige dark:text-white" />
					</div>
					<ul
						tabIndex={0}
						className="menu  dropdown-content bg-lt-light-popupBackground border  border-lt-light-popupBorder text-lt-light-popupText dark:text-white rounded-box z-[999] mt-3 w-52 p-2 shadow dark:bg-lt-dark-secondary-bg dark:border-lt-dark-secondary-border"
					>
						<li className="hover:bg-lt-yellow hover:rounded-md dark:active:text-white dark:hover:bg-lt-dark-secondary-hover-bg">
							<button
								className="hover:bg-transparent focus:bg-transparent  active:text-black dark:active:text-white dark:hover:bg-lt-dark-secondary-hover-bg`"
								onClick={toggleTheme}
							>
								{theme === 'light' ? <IconSun /> : <IconMoon />}
								{theme === 'light' ? 'Light Mode' : 'Dark Mode'}
							</button>
						</li>
						<li className="hover:bg-lt-yellow hover:rounded-md dark:active:text-white dark:hover:bg-lt-dark-secondary-hover-bg">
							<button
								className="hover:bg-transparent focus:bg-transparent  active:text-black dark:active:text-white dark:hover:bg-lt-dark-secondary-hover-bg`"
								onClick={openAYSModule}
							>
								<IconLogout />
								Logout
							</button>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}
