import { Helmet } from 'react-helmet';
import FAQ from './components/FAQ';
import Features from './components/Features';
import Footer from './components/Footer';
import Main from './components/Main';
import Navbar from './components/Navbar';
import Pricing from './components/Pricing';

export default function Home() {
	return (
		<main className="flex flex-col w-full items-center h-screen overflow-y-auto" id="home-container">
			<Helmet>
				<title>Life on Track - A simple way to track your daily life</title>
				<meta
					name="description"
					content="With Life on Track, keep track of your daily life, decorate your journal with stickers, share with friends!"
				/>
			</Helmet>
			<div className="flex flex-col items-center box-border max-w-[2000px]">
				<Navbar />
				<div className="overflow-y-auto flex flex-col items-center w-full gap-20">
					<Main />
					<Features />
					<FAQ />
					<Pricing />
					<Footer />
				</div>
			</div>
		</main>
	);
}
