import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './customBaseQuery';

export interface IUser {
	_id?: string;
	username: string;
	profileImgUrl?: string;
}

export interface User {
	data: IUser[];
	message: string;
}

export interface ILeaderBoard {
	stickerLeaderBoard: StickerLeaderBoard[];
	streakLeaderBoard: StreakLeaderBoard[];
	achievementsLeaderBoard: AchievementLeaderBoard[];
}

export interface StickerLeaderBoard {
	_id: string;
	username: string;
	profileImgUrl?: string;
	stickersCount: number;
}

export interface StreakLeaderBoard {
	_id: string;
	username: string;
	profileImgUrl?: string;
	streakDay: {
		dayCount: number;
	};
}

export interface AchievementLeaderBoard {
	_id: string;
	username: string;
	profileImgUrl?: string;
	achievementsCount: number;
}
export interface LeaderBoardResponse {
	data: ILeaderBoard[];
}

export enum ActivityType {
	TABLE_CREATED = 'TABLE_CREATED',
	TABLE_ACTIVE_DAY_UPDATED = 'TABLE_ACTIVE_DAY_UPDATED',

	QUEST_UNLOCKED = 'QUEST_UNLOCKED',

	STICKER_UNLOCKED = 'STICKER_UNLOCKED',

	USER_FOLLOWED_BY_YOU = 'USER_FOLLOWED_BY_YOU',
	USER_FOLLOWED_YOU = 'USER_FOLLOWED_YOU',

	JOURNAL_TABLE_PUBLISHED = 'JOURNAL_TABLE_PUBLISHED',
	JOURNAL_STICKER_ADDED = 'JOURNAL_STICKER_ADDED'
}

export interface IActivity {
	_id: string;
	user: {
		username: string;
		profileImgUrl?: string;
		_id: string;
	};
	type: ActivityType;
	detail: string;
	createdAt: string;
}

export interface Activity {
	data: IActivity[];
}

export const socialApi = createApi({
	reducerPath: 'socialApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		searchUsers: builder.query<User, string>({
			query: (query) => `api/social/search-users/?q=${query}`
		}),
		getLeaderBoard: builder.query<ILeaderBoard, void>({
			query: () => 'api/social/get-leaderBoard'
		}),
		getFollowingsActivities: builder.query<Activity, { page?: number; limit?: number }>({
			query: ({ page = 1, limit = 10 }) => `api/social/get-followings-activities?page=${page}&limit=${limit}`
		})
	})
});

export const { useLazySearchUsersQuery, useLazyGetLeaderBoardQuery, useLazyGetFollowingsActivitiesQuery } = socialApi;
