import { Helmet } from 'react-helmet';
import FriendActivities from '../../../components/social/friendActivities/FriendActivities';
import LeaderBoard from '../../../components/social/leaderBoard/LeaderBoard';
import SearchProfile from '../../../components/social/searchProfile/SearchProfile';

export default function Social() {
	return (
		<div className="relative w-full flex flex-col">
			<Helmet>
				<title>Life on Track - Social</title>
				<meta
					name="description"
					content="You can access leaderboards, friend activities, and other users' profiles from here."
				/>
			</Helmet>
			<div className="fixed md:relative w-full bg-lt-cream dark:bg-lt-dark-secondary-bg p-4 dark:border-lt-dark-secondary-border z-10">
				<div className="flex flex-row w-full justify-between items-center">
					<h1 className="text-4xl font-bold">Social</h1>
					<div className="w-auto flex justify-center">
						<SearchProfile />
					</div>
				</div>
			</div>
			<div className="flex-grow overflow-y-auto h-screen py-14 md:py-0 p-0 sm:p-5">
				<div className="max-w-[2000px] w-full mx-auto">
					<div className="flex flex-col items-center lg:items-baseline lg:flex-row justify-between gap-20 px-0 sm:px-10 pt-16 pb-40 w-full">
						<LeaderBoard />
						<FriendActivities />
					</div>
				</div>
			</div>
		</div>
	);
}
