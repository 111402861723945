import {
	IconCoins,
	IconDeviceGamepad2,
	IconLicense,
	IconLogout,
	IconMoon,
	IconShoppingBag,
	IconSun,
	IconTableHeart,
	IconUserCircle,
	IconUsersGroup,
	IconMessageChatbot
} from '@tabler/icons-react';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useLogout } from '../../hooks/useLogout';
import { useTheme } from '../../context/ThemeContext';
import { RootState } from '../../states/store';
import { useSelector } from 'react-redux';
import emailjs from 'emailjs-com';
import Button from '../button/button';
import toast from 'react-hot-toast';
import CustomModule from '../customModule/customModule';
import Subscription from '../../views/Dashboard/Subscription/Subscription';
import { SubscriptionType } from '../../services/api/profile';

const sidebarMenuItems = [
	{ name: 'My Tables', icon: <IconTableHeart />, path: '/dashboard/my-tables' },
	{ name: 'Profile', icon: <IconUserCircle />, path: '/dashboard/profile' },
	{ name: 'Social', icon: <IconUsersGroup />, path: '/dashboard/social' },
	{ name: 'My Journal', icon: <IconDeviceGamepad2 />, path: '/dashboard/my-journal' },
	{ name: 'Achievements', icon: <IconLicense />, path: '/dashboard/achievements' },
	{ name: 'Shop', icon: <IconShoppingBag />, path: '/dashboard/shop' }
];

interface SidebarItem {
	name: string;
	icon: React.ReactNode | ((theme: string) => React.ReactNode);
	clickable: boolean;
	isThemeToggle?: boolean;
	onClick?: () => void;
}

export default function Sidebar() {
	const [selectedItem, setSelectedItem] = useState<string | null>(null);
	const { theme, toggleTheme } = useTheme();
	const { profile } = useSelector((state: RootState) => state.profile);
	const { isLoggingOut } = useSelector((state: RootState) => state.auth);
	const logout = useLogout();
	const location = useLocation();
	const [text, setText] = useState('');
	const form = useRef<HTMLFormElement>(null);
	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

	function calculateTimeLeft() {
		const targetDate = new Date(new Date(profile.createdAt).getTime() + 7 * 24 * 60 * 60 * 1000);
		const difference = targetDate.getTime() - new Date().getTime();
		let timeLeft = {
			days: 0,
			hours: 0,
			minutes: 0,
			seconds: 0
		};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60)
			};
		}

		return timeLeft;
	}

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft());
		}, 1000);

		return () => clearInterval(timer);
	}, [profile.createdAt]);

	const handleFeedback = (e: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (form.current) {
			emailjs.sendForm('service_zgcz294', 'template_gn8rqvr', form.current, 'uL1bWw-DFCVEsHnAu').then(
				() => {
					toast.success('Feedback sent successfully!');
				},
				(err) => {
					console.error(err);
					toast.error('Failed to send feedback. Please try again later.');
				}
			);
		}
		if (form.current) {
			form.current.reset();
		}
		(document.getElementById('feedback_modal') as HTMLDialogElement)?.close();
	};

	const openFeedbackPopup = () => {
		(document.getElementById('feedback_modal') as HTMLDialogElement)?.showModal();
	};

	const openAYSModule = () => {
		(document.getElementById('sidebar-logout-ays-modal') as HTMLDialogElement).showModal();
	};

	const sidebarDefaultItems: SidebarItem[] = [
		{
			name: theme === 'light' ? 'Light Mode' : 'Dark Mode',
			icon: (theme: string) => (theme === 'light' ? <IconSun /> : <IconMoon />),
			clickable: true,
			onClick: toggleTheme,
			isThemeToggle: true
		},
		// { name: 'Messages', icon: <IconMessages />, clickable: true, onClick: () => console.log('Messages clicked') },
		{
			name: profile.appMoney || profile.appMoney === 0 ? profile.appMoney.toString() : 'Loading',
			icon: <IconCoins />,
			clickable: false
		},
		{ name: 'Feedback', icon: <IconMessageChatbot />, clickable: true, onClick: openFeedbackPopup },
		{ name: 'Logout', icon: <IconLogout />, clickable: true, onClick: openAYSModule }
	];

	useEffect(() => {
		const currentItem = sidebarMenuItems.find((item) => item.path === location.pathname);
		if (currentItem) {
			setSelectedItem(currentItem.name);
		}
	}, [location.pathname]);

	const renderIcon = (icon: React.ReactNode | ((theme: string) => React.ReactNode), theme: string) => {
		return typeof icon === 'function' ? icon(theme) : icon;
	};

	return (
		<div className="overflow-hidden m-4 min-w-44">
			<CustomModule
				id="sidebar-logout-ays-modal"
				title="Logout"
				description="Are you sure you want to logout?"
				buttonText="Logout"
				onButtonClick={() => {
					logout();
				}}
				isLoading={isLoggingOut}
			/>
			<Subscription id="subscription-modal" />
			<dialog id="feedback_modal" className="modal ">
				<div className="modal-box bg-lt-lightCream dark:bg-lt-dark-popupBackground">
					<div className="p-8  w-full max-w-md">
						<form method="dialog">
							<button
								onClick={(e) => {
									e.preventDefault();
									(document.getElementById('feedback_modal') as HTMLDialogElement)?.close();
									setText('');
								}}
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							>
								✕
							</button>
						</form>
						<h2 className="text-2xl font-bold mb-2 text-center">Feedback Form</h2>
						<h6 className="mb-4">
							We would love to hear your thoughts, suggestions, and feedback! We will offer in-app sticker rewards for
							feedback that we find suitable and valuable :).
						</h6>
						<form ref={form} onSubmit={handleFeedback} className="space-y-4">
							<div>
								<input
									onChange={(e) => {
										e.preventDefault();
										(document.getElementById('feedback_modal') as HTMLDialogElement)?.close();
									}}
									className="hidden"
									type="text"
									name="from_name"
									value={profile.username || ''}
								/>
								<input
									onChange={(e) => {
										e.preventDefault();
										(document.getElementById('feedback_modal') as HTMLDialogElement)?.close();
									}}
									className="hidden"
									type="text"
									name="user_email"
									value={profile.email || ''}
								/>
								<label htmlFor="message" className="block text-sm font-medium text-gray-700 dark:text-white">
									Message
								</label>
								<textarea
									onChange={(e) => setText(e.target.value)}
									value={text}
									id="message"
									name="message"
									className="mt-1 p-2 w-full border border-gray-300 rounded-md dark:text-black max-h-80"
									rows={4}
									required
								></textarea>
							</div>
							<Button variant="btn-primary-light" type="submit" className="w-full py-2 rounded-md">
								Send Feedback
							</Button>
						</form>
					</div>
				</div>
			</dialog>
			<div
				className={`relative bg-lt-cream ${
					theme === 'dark' ? 'dark:bg-lt-dark-secondary-bg dark:border-lt-dark-secondary-border' : ''
				} rounded border-2 border-lt-beige  w-full`}
			>
				{/* <button className="bg-transparent p-2 absolute top-0 left-0 m-4" type="button" onClick={handleMenu}>
					<IconMenu2 />
				</button> */}
				<div className={`transition-opacity duration-500 ease-in-out`}>
					<ul className={`menu w-full gap-1 py-2`}>
						{sidebarMenuItems.map((item, index) => (
							<li className="px-2" key={index}>
								<Link
									className={`border ${selectedItem === item.name ? 'bg-lt-yellow border-2 border-lt-beige dark:bg-lt-dark-popupBackground dark:border-lt-dark-popupBackground dark:text-lt-dark-popupText' : 'border-2 border-transparent'} focus:bg-lt-yellow hover:bg-lt-yellow active:text-black dark:active:text-white dark:hover:bg-lt-dark-secondary-hover-bg `}
									to={item.path}
									onClick={() => setSelectedItem(item.name)}
								>
									<div className="flex items-center gap-1 text-base">
										{item.icon}
										<span>{item.name}</span>
									</div>
								</Link>
							</li>
						))}
					</ul>
				</div>
			</div>
			<div
				className={`bg-lt-cream ${
					theme === 'dark' ? 'dark:bg-lt-dark-secondary-bg dark:border-lt-dark-secondary-border' : ''
				} rounded border-2 border-lt-beige min-h-40 mt-4`}
			>
				<ul className="menu w-full gap-1 py-2">
					{sidebarDefaultItems.map((item, index) => (
						<li className={`px-2 ${item.clickable ? '' : 'pointer-events-none'}`} key={index}>
							<div
								onClick={item.onClick}
								className={`flex items-center gap-1 text-base ${item.clickable ? 'cursor-pointer hover:bg-lt-yellow' : 'cursor-default'} border border-transparent focus:bg-lt-yellow hover:bg-lt-yellow active:text-black dark:active:text-white dark:hover:bg-lt-dark-secondary-hover-bg`}
							>
								{renderIcon(item.icon, theme)}
								<span>{item.name}</span>
							</div>
						</li>
					))}
				</ul>
			</div>

			{profile.subscription.subscriptionType === SubscriptionType.FREE && profile.subscription.isTrial && (
				<>
					{timeLeft.days || timeLeft.hours || timeLeft.minutes || timeLeft.seconds ? (
						<>
							{timeLeft.days < 3 && (
								<div className="flex flex-col gap-4 bg-lt-cream dark:bg-lt-dark-secondary-bg rounded border-2 border-lt-beige dark:border-lt-dark-secondary-border p-2 mt-4 max-w-[200px]">
									<div className="flex flex-col gap-1">
										<div className="flex  justify-between px-4">
											<span className="text-xs font-bold">D</span>
											<span className="text-xs font-bold">H</span>
											<span className="text-xs font-bold">M</span>
											<span className="text-xs font-bold">S</span>
										</div>
										<div className="flex justify-center space-x-1">
											<div className="flex flex-col items-center">
												<div className="flex items-center justify-center w-8 h-8 border border-lt-beige dark:border-lt-dark-secondary-border rounded-lg text-sm">
													{String(timeLeft.days).padStart(2, '0')}
												</div>
											</div>
											<span className="text-xl flex items-center">:</span>
											<div className="flex flex-col items-center">
												<div className="flex items-center justify-center  w-8 h-8 border border-lt-beige dark:border-lt-dark-secondary-border rounded-lg text-sm">
													{String(timeLeft.hours).padStart(2, '0')}
												</div>
											</div>
											<span className="text-xl flex items-center">:</span>
											<div className="flex flex-col items-center">
												<div className="flex items-center justify-center  w-8 h-8 border border-lt-beige dark:border-lt-dark-secondary-border rounded-lg text-sm">
													{String(timeLeft.minutes).padStart(2, '0')}
												</div>
											</div>
											<span className="text-xl flex items-center">:</span>
											<div className="flex flex-col items-center">
												<div className="flex items-center justify-center  w-8 h-8 border border-lt-beige dark:border-lt-dark-secondary-border rounded-lg text-sm">
													{String(timeLeft.seconds).padStart(2, '0')}
												</div>
											</div>
										</div>
									</div>

									<p className="text-center text-xs">
										<strong>Your free trial is about to end!</strong> Extend your subscription to maintain full access
										to Life on Track.
									</p>
									<button
										onClick={() => {
											(document.getElementById('subscription-modal') as HTMLDialogElement).showModal();
										}}
										type={'button'}
										className="bg-lt-beige rounded-md w-full py-2 font-semibold text-white border border-lt-beige dark:border-lt-dark-primary-border dark:bg-lt-dark-primary-bg dark:text-lt-dark-popupText dark:hover:bg-lt-dark-primary-hover-bg"
									>
										Extend Subscription
									</button>
								</div>
							)}
						</>
					) : null}
				</>
			)}
		</div>
	);
}
