import { IconCaretDown, IconChevronLeft, IconChevronRight } from '@tabler/icons-react';
import {
	GetFollowDataResponse,
	useLazyGetFollowDataQuery,
	useLazyGetFriendsFollowDataQuery,
	useSendFollowRequestMutation,
	useUnfollowMutation
} from '../../../services/api/profile';
import noProfileImg from '../../../assets/no-profile-picture.svg';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import './Followings.css';
import { getMyFollowingsSuccess, unfollowSuccess } from '../../../states/profile/profileSlice';

import noFriends from '/src/assets/emptyStates/noFriends.webp';
import CustomModule from '../../customModule/customModule';
import { RootState } from '../../../states/store';
import { IFriend } from '../../../types/profile.types';
import Button from '../../button/button';

export default function Followings({
	setIsFollowing,
	isFollowing,
	setFollowRequestSendFlag,
	followRequestSendFlag
}: {
	setIsFollowing: React.Dispatch<React.SetStateAction<boolean>>;
	isFollowing?: boolean;
	setFollowRequestSendFlag: React.Dispatch<React.SetStateAction<boolean>>;
	followRequestSendFlag: boolean;
}) {
	const { userId } = useParams<string>();
	const dispatch = useDispatch();
	const [followDataRequest] = useLazyGetFollowDataQuery();
	const [friendsFollowDataRequest] = useLazyGetFriendsFollowDataQuery();
	const [isFollowDataLoading, setIsFollowDataLoading] = useState(false);
	const [isFriendsFollowDataLoading, setIsFriendsFollowDataLoading] = useState(false);
	const [followData, setFollowData] = useState<GetFollowDataResponse>();
	const [friendsFollowData, setFriendsFollowData] = useState<GetFollowDataResponse>();
	const [unfollowRequest, { isLoading: unfollowIsLoading }] = useUnfollowMutation();
	const [page, setPage] = useState(1);
	const [isOpen, setIsOpen] = useState(false);
	const dropdownRef = useRef<HTMLDivElement>(null);
	const [selectedFriendId, setSelectedFriendId] = useState('');
	const { profile } = useSelector((state: RootState) => state.profile);
	const [isFollowBackRequestLoading, setIsFollowBackRequestLoading] = useState(false);
	const [sendFollowRequest] = useSendFollowRequestMutation();
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);

	useEffect(() => {
		const getFollowings = async () => {
			try {
				setIsFollowDataLoading(true);
				const response = await followDataRequest({
					page: page,
					limit: 10
				}).unwrap();
				if (response) {
					dispatch(getMyFollowingsSuccess(response.data.followings));
					setFollowData(response);
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			} finally {
				setIsFollowDataLoading(false);
			}
		};
		if (userId || !isAuthenticated) return;
		getFollowings();
	}, [page, profile.followings, isAuthenticated]);

	useEffect(() => {
		const getFriendsFollowings = async () => {
			try {
				setIsFriendsFollowDataLoading(true);
				const response = await friendsFollowDataRequest({
					userId: userId as string,
					page: page,
					limit: 10
				}).unwrap();
				if (response) {
					setFriendsFollowData(response);
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			} finally {
				setIsFriendsFollowDataLoading(false);
			}
		};
		if (!userId) return;
		getFriendsFollowings();
	}, [page, userId]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent | TouchEvent | PointerEvent | KeyboardEvent | FocusEvent | Event) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};
		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const sendFollowBackRequest = async (userId: string) => {
		try {
			setIsFollowBackRequestLoading(true);
			const response = await sendFollowRequest(userId).unwrap();
			if (response) {
				toast.success(response.message);
				setFollowRequestSendFlag(true);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		} finally {
			setIsFollowBackRequestLoading(false);
		}
	};

	const unfollow = async () => {
		try {
			if (!selectedFriendId) return;
			const response = await unfollowRequest(selectedFriendId).unwrap();
			if (response) {
				dispatch(unfollowSuccess(response.followingId));
				toast.success(response.message);
				setFollowData(
					(prev) =>
						({
							...prev,
							data: {
								...prev?.data,
								followings: prev?.data.followings.filter((following) => following._id !== response.followingId)
							}
						}) as GetFollowDataResponse
				);
				setIsFollowing(false);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred');
			}
		} finally {
			(document.getElementById('friends-ays-modal') as HTMLDialogElement).close();
		}
	};

	const handleDropdownToggle = () => {
		setIsOpen((prev) => !prev);
	};

	const handlePageSelect = (page: number) => {
		setPage(page);
		setIsOpen(false);
	};

	const openAYSModule = (id: string) => {
		(document.getElementById('friends-ays-modal') as HTMLDialogElement).showModal();
		setSelectedFriendId(id);
	};

	const closeFollowingsModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		(document.getElementById('followings_modal') as HTMLDialogElement)?.close();
	};

	return (
		<div className="flex items-end">
			<CustomModule
				id="friends-ays-modal"
				title="Are you sure you want to unfollow?"
				description="You will no longer be able to see their actions."
				buttonText="Unfollow"
				onButtonClick={() => {
					unfollow();
				}}
				isLoading={unfollowIsLoading}
			/>
			<dialog id="followings_modal" className="modal">
				<div className="p-0 modal-box max-w-2xl h-[600px] bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground border border-lt-light-popupBorder dark:border-lt-dark-popupBorder relative overflow-hidden">
					<div className="sticky top-0 bg-lt-light-popupBackground dark:bg-lt-dark-popupBackground z-10 p-4">
						<form method="dialog">
							<button
								onClick={(e) => closeFollowingsModal(e)}
								className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
							>
								✕
							</button>
						</form>
						<h3 className="font-bold text-3xl">{!isFollowing && !userId ? 'My' : 'Friends'} Followings</h3>
					</div>
					<div className="h-[calc(100%-116px)] overflow-y-auto">
						{!isFollowing && !userId ? (
							<>
								<div className="p-4 mt-2">
									{isFollowDataLoading ? (
										<div className="w-full min-h-[288px] h-full flex justify-center items-center">
											<span className="loading loading-spinner loading-xs"></span>
										</div>
									) : (
										<>
											{followData && followData.data.followings.length > 0 ? (
												<ul className="flex flex-col gap-3">
													{followData.data.followings.map((following: IFriend, _index: number) => (
														<li key={following._id} className="relative pb-4">
															<div className="flex justify-between items-center">
																<Link to={`/dashboard/profile/${following._id}`}>
																	<div className="flex items-center hover:text-lt-beige transition-colors duration-300">
																		<img
																			src={following.profileImgUrl ?? noProfileImg}
																			alt="profile"
																			className="w-6 h-6 sm:w-10 sm:h-10 rounded-full object-cover"
																		/>
																		<span className="ml-2 font-semibold sm:text-xl">{following.username}</span>
																	</div>
																</Link>
																<Button
																	onClick={() => openAYSModule(following._id)}
																	type="button"
																	variant="btn-secondary-light"
																	className="bg-lt-beige dark:bg-lt-dark-secondary-bg text-white dark:text-white px-2 py-1 text-xs sm:text-base rounded-md"
																>
																	Remove
																</Button>
															</div>
															<div className="absolute bottom-0 left-0 right-0 h-px bg-lt-beige dark:bg-[#6f6b6b]"></div>
														</li>
													))}
												</ul>
											) : (
												<div className="min-h-[500px] w-full h-full flex flex-col gap-6 justify-center items-center">
													<img className="w-[200px] h-[135px]" src={noFriends} alt="No followings" />
													<span>No followings yet. Follow someone to see them here.</span>
												</div>
											)}
										</>
									)}
								</div>
							</>
						) : (
							<>
								<div className="p-4 mt-2">
									{isFriendsFollowDataLoading ? (
										<div className="w-full min-h-[288px] h-full flex justify-center items-center">
											<span className="loading loading-spinner loading-xs"></span>
										</div>
									) : (
										<>
											{friendsFollowData && friendsFollowData.data.followings.length > 0 ? (
												<ul className="flex flex-col gap-3">
													{friendsFollowData.data.followings.map((following: IFriend, index: number) => (
														<li key={following._id} className="relative pb-4">
															<div className="flex justify-between items-center">
																<Link to={`/dashboard/profile/${following._id}`} className="">
																	<div className="flex items-center">
																		<img
																			src={following.profileImgUrl ?? noProfileImg}
																			alt="profile"
																			className="w-6 h-6 sm:w-10 sm:h-10 rounded-full object-cover"
																		/>
																		<span className="ml-2 font-semibold sm:text-xl">{following.username}</span>
																	</div>
																</Link>
																{profile._id !== following._id && (
																	<>
																		{profile.followings.includes(following._id) ? (
																			<span className="text-gray-400">Following</span>
																		) : (
																			<Button
																				type="button"
																				variant="btn-secondary-light"
																				disabled={isFollowBackRequestLoading || followRequestSendFlag}
																				className="bg-lt-beige dark:bg-lt-dark-secondary-bg text-white dark:text-white px-2 py-1 text-xs sm:text-base rounded-md"
																				onClick={() => {
																					sendFollowBackRequest(following._id);
																				}}
																			>
																				Follow
																			</Button>
																		)}
																	</>
																)}
															</div>
															{index !== friendsFollowData.data.followings.length - 1 && (
																<div className="absolute bottom-0 left-0 right-0 h-px bg-lt-beige dark:bg-[#6f6b6b]"></div>
															)}
														</li>
													))}
												</ul>
											) : (
												<div className="min-h-[500px] w-full h-full flex flex-col gap-6 justify-center items-center">
													<img className="w-[200px] h-[135px]" src={noFriends} alt="No followings" />
													<span>No followings yet.</span>
												</div>
											)}
										</>
									)}
								</div>
							</>
						)}
					</div>
					<div className="absolute right-0 left-0 bottom-0 z-20 bg-lt-lightCream dark:bg-lt-dark-secondary-bg flex justify-between border-t border-lt-beige dark:border-lt-dark-popupBorder">
						<button
							disabled={Boolean(
								isFollowDataLoading ||
									isFriendsFollowDataLoading ||
									page === 1 ||
									(!userId && followData?.pagination.totalFollowingPages === 0) ||
									(userId && friendsFollowData?.pagination.totalFollowingPages === 0)
							)}
							onClick={() => {
								if (page === 1) return;
								setPage(page - 1);
							}}
							className="btn bg-transparent border-y-0 border-l-0 rounded-none border-r border-lt-beige dark:border-lt-dark-popupBorder shadow-none h-[58px]"
						>
							<IconChevronLeft />
						</button>
						<div ref={dropdownRef} className="relative">
							<button
								className="btn bg-transparent shadow-none border-y-0 rounded-none border-x border-lt-beige dark:border-lt-dark-popupBorder h-[58px]"
								onClick={handleDropdownToggle}
							>
								{userId ? friendsFollowData?.pagination.page : followData?.pagination.page} of{' '}
								{userId
									? friendsFollowData?.pagination.totalFollowingPages
									: followData?.pagination.totalFollowingPages}
								<IconCaretDown className="w-4 h-4" />
							</button>
							{isOpen && (
								<ul className="menu dropdown-content rounded-md z-[1] bg-lt-lightCream dark:bg-lt-dark-popupBackground w-28 p-0 shadow absolute bottom-full left-1/2 -translate-x-1/2 max-h-60 overflow-y-auto border border-lt-beige dark:border-lt-dark-popupBorder">
									{Array(
										userId
											? friendsFollowData?.pagination.totalFollowingPages
											: followData?.pagination.totalFollowingPages
									)
										.fill(null)
										.map((_, i, arr) => (
											<li key={i}>
												<button
													disabled={isFollowDataLoading || isFriendsFollowDataLoading}
													className={`h-10 rounded-sm border-x-0 border-t-0 w-full ${i !== arr.length - 1 ? 'border-b-2 border-lt-beige dark:border-lt-dark-popupBorder' : ''}`}
													onClick={() => handlePageSelect(i + 1)}
													value={i + 1}
												>
													{i + 1}
												</button>
											</li>
										))}
								</ul>
							)}
						</div>
						<button
							disabled={Boolean(
								isFollowDataLoading ||
									isFriendsFollowDataLoading ||
									(!userId && page === followData?.pagination.totalFollowingPages) ||
									(userId && page === friendsFollowData?.pagination.totalFollowingPages) ||
									(!userId && followData?.pagination.totalFollowingPages === 0) ||
									(userId && friendsFollowData?.pagination.totalFollowingPages === 0)
							)}
							onClick={() => {
								if (page === 10) return;
								setPage(page + 1);
							}}
							className="btn bg-transparent border-y-0 border-r-0 rounded-none border-l border-lt-beige dark:border-lt-dark-popupBorder shadow-none h-[58px]"
						>
							<IconChevronRight />
						</button>
					</div>
				</div>
			</dialog>
			<div>
				<span className="flex gap-1">
					{userId && isFollowing ? (
						<>
							<strong>{friendsFollowData?.pagination.totalFollowings}</strong>
							<button
								disabled={friendsFollowData?.pagination.totalFollowings === 0}
								onClick={() => (document.getElementById('followings_modal') as HTMLDialogElement)?.showModal()}
								className="text-lt-beige font-semibold text-xs sm:text-base"
							>
								Followings
							</button>
						</>
					) : (
						<>
							<strong>{followData?.pagination.totalFollowings}</strong>
							<button
								disabled={!isFollowing && followData?.data.followings.length === 0}
								onClick={() => (document.getElementById('followings_modal') as HTMLDialogElement)?.showModal()}
								className={`text-lt-beige dark:text-lt-dark-popupText font-semibold text-xs sm:text-base ${followData?.data.followings.length === 0 ? '' : 'dark:hover:text-lt-beige transition-colors duration-300'}`}
							>
								Followings
							</button>
						</>
					)}
				</span>
			</div>
		</div>
	);
}
