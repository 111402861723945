import { useDispatch, useSelector } from 'react-redux';
import { useClaimAchievementMutation, useGetAchievementsQuery } from '../../../services/api/achievement';
import { RootState } from '../../../states/store';
import { useEffect, useState } from 'react';
import { getAchievementsSuccess } from '../../../states/achievements/achievementsSlice';
import { IAchievement } from '../../../types/achievement.types';
import CustomModule from '../../../components/customModule/customModule';
import { IconCoins, IconLicense } from '@tabler/icons-react';
import Button from '../../../components/button/button';
import { claimAchievementSuccessProfile } from '../../../states/profile/profileSlice';
import toast from 'react-hot-toast';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import { Helmet } from 'react-helmet';

export default function Achievements() {
	const dispatch = useDispatch();
	const { achievements } = useSelector((state: RootState) => state.achievements);
	const { profile } = useSelector((state: RootState) => state.profile);
	const { data: achievementsData, isLoading } = useGetAchievementsQuery();
	const [claimAchievementRequest, { isLoading: claimAchievementIsLoading }] = useClaimAchievementMutation();
	const [selectedAchievement, setSelectedAchievement] = useState<IAchievement>();

	useEffect(() => {
		if (achievements.length === 0 || !achievements) {
			if (achievementsData && achievementsData.achievements) {
				dispatch(getAchievementsSuccess(achievementsData.achievements));
			}
		}
	}, [achievementsData]);

	const groupAchievementsByType = (achievements: IAchievement[]) => {
		const groupedAchievements = {} as Record<string, IAchievement[]>;

		achievements.forEach((ach) => {
			let type = ach.type.split('_')[0];
			// Move friend related achievements to user category
			if (type === 'FRIEND') {
				type = 'USER';
			}
			if (!groupedAchievements[type]) {
				groupedAchievements[type] = [];
			}
			groupedAchievements[type].push(ach);
		});

		// Sort achievements by reward within each category
		Object.keys(groupedAchievements).forEach((type) => {
			groupedAchievements[type].sort((a, b) => b.reward - a.reward);
		});

		return groupedAchievements;
	};

	const claimAchievement = async () => {
		try {
			if (!selectedAchievement) return;
			const response = await claimAchievementRequest({ achievementId: selectedAchievement._id }).unwrap();

			if (response) {
				dispatch(
					claimAchievementSuccessProfile({
						achId: selectedAchievement._id,
						reward: selectedAchievement.reward
					})
				);
				toast.success(response.message);
			}
		} catch (error: unknown) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please refresh the page.');
			}
		} finally {
			(document.getElementById('claim-modal') as HTMLDialogElement).close();
		}
	};

	const openClaimModal = (achievement: IAchievement) => {
		(document.getElementById('claim-modal') as HTMLDialogElement).showModal();
		setSelectedAchievement(achievement);
	};
	const groupedAchievements = groupAchievementsByType(achievements);

	const formatGroupName = (groupName: string) => {
		return groupName.split('_').join(' ') + ' RELATED ACHIEVEMENTS';
	};

	return (
		<div className="w-full">
			<Helmet>
				<title>Achievements</title>
				<meta
					name="description"
					content="Here you can view all the achievements. You can claim your rewards by clicking on the 'Claim' button."
				/>
			</Helmet>
			<div className="sticky top-0 left-0 right-0 p-4 bg-lt-cream dark:bg-lt-dark-secondary-bg w-full z-10">
				<h1 className="text-4xl font-bold">Achievements</h1>
			</div>
			{isLoading ? (
				<div className="w-full flex justify-center items-center h-screen">
					<span className="loading loading-spinner loading-lg text-center"></span>
				</div>
			) : (
				<div className="flex-grow h-screen overflow-y-auto">
					<div className="max-w-[2000px] w-full mx-auto">
						<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 p-6 pb-[300px] sm:pb-52">
							{Object.entries(groupedAchievements).map(([type, achievements]) => (
								<div key={type} className="bg-lt-lightCream dark:bg-lt-dark-secondary-bg rounded-lg p-6 shadow-md">
									<div className="h-16 mb-4 border-b-2 border-lt-beige dark:border-lt-dark-primary-border">
										<h2 className="text-2xl font-bold text-lt-dark dark:text-lt-white line-clamp-2">
											{formatGroupName(type)}
										</h2>
									</div>
									<div className="space-y-4">
										{(achievements as IAchievement[]).map((achievement: IAchievement, index: number) => (
											<div
												key={index}
												className={`bg-lt-cream dark:bg-lt-dark-popupBackground rounded-lg p-4 ${
													profile.achievements.find(
														(a: { _id: string; isClaimed: boolean }) => a._id === achievement._id && a.isClaimed
													)
														? 'opacity-75'
														: ''
												}`}
											>
												<CustomModule
													id="claim-modal"
													title={`Claim ${selectedAchievement?.title}`}
													description={`Your balance will be updated with ${selectedAchievement?.reward} coins.`}
													buttonText="Claim"
													onButtonClick={() => {
														claimAchievement();
													}}
													isLoading={claimAchievementIsLoading}
												/>
												<div className="flex items-start space-x-4">
													<IconLicense
														className={`flex-shrink-0 mt-1 ${
															profile.achievements.find(
																(a: { _id: string; isClaimed: boolean }) => a._id === achievement._id && a.isClaimed
															)
																? 'text-lt-dark-primary-disabled-text'
																: 'text-lt-dark dark:text-lt-white'
														}`}
													/>
													<div className="flex-grow">
														<h3
															className={`font-bold ${
																profile.achievements.find(
																	(a: { _id: string; isClaimed: boolean }) => a._id === achievement._id && a.isClaimed
																)
																	? 'line-through text-lt-dark-primary-disabled-text'
																	: 'text-lt-dark dark:text-lt-white'
															}`}
														>
															{achievement.title}
														</h3>
														<p
															className={`text-sm mt-1 ${
																profile.achievements.find(
																	(a: { _id: string; isClaimed: boolean }) => a._id === achievement._id && a.isClaimed
																)
																	? 'line-through text-lt-dark-primary-disabled-text'
																	: 'text-lt-dark-gray dark:text-lt-dark-secondary-text'
															}`}
														>
															{achievement.description}
														</p>
														<div className="flex items-center justify-between mt-3">
															<div className="flex items-center space-x-1">
																<IconCoins
																	className={
																		profile.achievements.find(
																			(a: { _id: string; isClaimed: boolean }) =>
																				a._id === achievement._id && a.isClaimed
																		)
																			? 'text-lt-dark-primary-disabled-text'
																			: 'text-lt-gold'
																	}
																/>
																<span
																	className={
																		profile.achievements.find(
																			(a: { _id: string; isClaimed: boolean }) =>
																				a._id === achievement._id && a.isClaimed
																		)
																			? 'line-through text-lt-dark-primary-disabled-text'
																			: 'font-bold text-lt-gold'
																	}
																>
																	{achievement.reward}
																</span>
															</div>
															{profile.achievements.find(
																(a: { _id: string; isClaimed: boolean }) =>
																	a._id === achievement._id && a.isClaimed !== undefined
															) && (
																<Button
																	variant="btn-primary-light"
																	className="text-sm rounded-md border-none btn-sm hover:bg-lt-beige dark:hover:bg-lt-dark-primary-hover transition-colors"
																	type="button"
																	onClick={() => openClaimModal(achievement)}
																	disabled={
																		profile.achievements.find(
																			(a: { _id: string; isClaimed: boolean }) =>
																				a._id === achievement._id && a.isClaimed
																		)
																			? true
																			: false
																	}
																>
																	{profile.achievements.find(
																		(a: { _id: string; isClaimed: boolean }) => a._id === achievement._id && a.isClaimed
																	)
																		? 'Claimed'
																		: 'Claim'}
																</Button>
															)}
														</div>
													</div>
												</div>
											</div>
										))}
									</div>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
