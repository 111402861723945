import { FC, useRef, useState, useEffect } from 'react';
import { useGLTF } from '@react-three/drei';
// import { FC, Suspense, useRef, useState, useEffect } from 'react';
// import { Bounds, OrbitControls, useBounds, useGLTF, useTexture } from '@react-three/drei';
// import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
// import * as THREE from 'three';
// import { Canvas, useFrame } from '@react-three/fiber';
import HTMLFlipBook from 'react-pageflip';
import { useSpring, animated } from '@react-spring/web';
import './MyJournal.css';
import { RootState } from '../../../states/store';
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../components/myTables/table/Table';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import * as React from 'react';
import { ItemTypes, JournalPage, Location, StickerLocations } from './ItemTypes';
import Button from '../../../components/button/button';
import { Link, useParams } from 'react-router-dom';
import { IconInfoCircle, IconMinus } from '@tabler/icons-react';
import { useUpdateMyJournalMutation } from '../../../services/api/myJournal';
import {
	achievementEarnedSuccess,
	getMyJournalSuccess,
	getMyStickersSuccess
} from '../../../states/profile/profileSlice';
import toast from 'react-hot-toast';
import { isCustomErrorResponse } from '../../../utils/errorUtils';
import {
	FriendsJournalResponse,
	useLazyGetFriendsJournalQuery,
	useLazyGetMyStickersQuery
} from '../../../services/api/profile';
import AchievementToast from '../../../components/profile/achievements/AchievementToast';
import { IAchievement } from '../../../types/achievement.types';
import { useLazyGetTablesQuery } from '../../../services/api/myTables';
import { getTablesSuccess } from '../../../states/table/myTablesSlice';
import { Helmet } from 'react-helmet';

// type GLTFResult = GLTF & {
// 	nodes: {
// 		Plane002: THREE.Mesh;
// 		Plane002_1: THREE.Mesh;
// 		Desk: THREE.Mesh;
// 		Chair_1: THREE.Mesh;
// 		Book2_Open_1: THREE.Mesh;
// 		Book2_Open_2: THREE.Mesh;
// 		Book2_Open_3: THREE.Mesh;
// 	};
// 	materials: {
// 		floor: THREE.MeshStandardMaterial;
// 		wall: THREE.MeshStandardMaterial;
// 		Wood: THREE.MeshStandardMaterial;
// 		Beige: THREE.MeshStandardMaterial;
// 		DarkRed: THREE.MeshStandardMaterial;
// 		Book: THREE.MeshStandardMaterial;
// 	};
// };

// function SelectToZoom({ children, onBookClick }: { children: React.ReactNode; onBookClick: () => void }) {
// 	const api = useBounds();
// 	return (
// 		<group
// 			onClick={(e) => {
// 				if (e.object.name === 'Book2_Open_1' || e.object.name === 'Book2_Open_2' || e.object.name === 'Book2_Open_3') {
// 					e.stopPropagation();
// 					api.refresh(e.object).fit();
// 					onBookClick();
// 				}
// 			}}
// 		>
// 			{children}
// 		</group>
// 	);
// }

// enum TextureEncoding {
// 	LinearEncoding = 3000,
// 	sRGBEncoding = 3001,
// 	GammaEncoding = 3007,
// 	RGBEEncoding = 3002,
// 	LogLuvEncoding = 3003,
// 	RGBM7Encoding = 3004,
// 	RGBM16Encoding = 3005,
// 	RGBDEncoding = 3006
// }

// interface ExtendedTexture extends THREE.Texture {
// 	encoding: TextureEncoding;
// }

// const Model: FC<{ onBookClick: () => void }> = ({ onBookClick, ...props }) => {
// 	const { nodes } = useGLTF('/baked_1.glb') as unknown as GLTFResult;
// 	const texture = useTexture('/textures/Baked.jpg') as ExtendedTexture;
// 	texture.flipY = false;
// 	texture.encoding = TextureEncoding.sRGBEncoding;

// 	const textureMaterial = new THREE.MeshStandardMaterial({ map: texture });

// 	return (
// 		<group {...props} dispose={null}>
// 			<group position={[-0.081, 0.275, -0.044]} scale={1.937}>
// 				<mesh castShadow receiveShadow geometry={nodes.Plane002.geometry} material={textureMaterial} />
// 				<mesh castShadow receiveShadow geometry={nodes.Plane002_1.geometry} material={textureMaterial} />
// 			</group>
// 			<mesh
// 				castShadow
// 				receiveShadow
// 				geometry={nodes.Desk.geometry}
// 				material={textureMaterial}
// 				position={[-1.4, 0.281, 0.206]}
// 				rotation={[0.876, 1.562, -0.881]}
// 				scale={183.521}
// 			/>
// 			<mesh
// 				castShadow
// 				receiveShadow
// 				geometry={nodes.Chair_1.geometry}
// 				material={textureMaterial}
// 				position={[-0.457, 0.279, 0.288]}
// 				rotation={[-1.562, -0.013, -2.276]}
// 				scale={[73.771, 73.772, 72.964]}
// 			/>
// 			<Suspense fallback={null}>
// 				<Bounds fit clip observe margin={1.2}>
// 					<SelectToZoom onBookClick={onBookClick}>
// 						<group position={[-1.328, 1.131, -0.352]} rotation={[-1.576, 0.007, 1.565]} scale={59.376}>
// 							<mesh
// 								castShadow
// 								receiveShadow
// 								geometry={nodes.Book2_Open_1.geometry}
// 								material={textureMaterial}
// 								name="Book2_Open_1"
// 							/>
// 							<mesh
// 								castShadow
// 								receiveShadow
// 								geometry={nodes.Book2_Open_2.geometry}
// 								material={textureMaterial}
// 								name="Book2_Open_2"
// 							/>
// 							<mesh
// 								castShadow
// 								receiveShadow
// 								geometry={nodes.Book2_Open_3.geometry}
// 								material={textureMaterial}
// 								name="Book2_Open_3"
// 							/>
// 						</group>
// 					</SelectToZoom>
// 				</Bounds>
// 			</Suspense>
// 		</group>
// 	);
// };

useGLTF.preload('/baked_1.glb');

function MyJournal() {
	// const [isBookOpen, setIsBookOpen] = useState(false);
	const [showFlipBook, setShowFlipBook] = useState(false);
	// const orbitControlsRef = useRef(null);

	// const handleBookClick = () => {
	// 	setIsBookOpen(true);
	// };

	// useEffect(() => {
	// 	let timeout: NodeJS.Timeout;
	// 	if (isBookOpen) {
	// 		timeout = setTimeout(() => setShowFlipBook(true), 1000);
	// 	}
	// 	return () => clearTimeout(timeout);
	// }, [isBookOpen]);
	useEffect(() => {
		const timeout: NodeJS.Timeout = setTimeout(() => setShowFlipBook(true), 1000);
		return () => clearTimeout(timeout);
	}, []);

	// const { opacity } = useSpring({
	// 	opacity: isBookOpen ? 0 : 1,
	// 	config: { duration: 1000 }
	// });
	const { opacity } = useSpring({
		opacity: showFlipBook ? 1 : 0,
		config: { duration: 2000 }
	});

	// const { opacity: flipBookOpacity } = useSpring({
	// 	opacity: showFlipBook ? 1 : 0,
	// 	config: { duration: 1000 }
	// });

	return (
		<div className="w-full">
			<Helmet>
				<title>Life on Track - My Journal</title>
				<meta
					name="description"
					content="The Virtual Journal allows you and your friends to view your selected tables in an organized and appealing way. From here, you can explore your virtual journal and decorate it with stickers."
				/>
			</Helmet>
			<div className="relative hidden base:block  overflow-hidden h-screen flex-grow">
				{/* <animated.div style={{ opacity, width: '100%', height: '100%' }}>
					<Canvas
						camera={{
							position: [20, 5, 6],
							fov: 30,
							near: 0.1,
							far: 1000
						}}
						onCreated={({ camera }) => {
							camera.lookAt(new THREE.Vector3(-1.328, 1.131, -0.352));
						}}
					>
						<ambientLight intensity={0.5} />
						<spotLight intensity={0.5} position={[10, 10, 10]} angle={0.15} penumbra={1} />
						<Model onBookClick={handleBookClick} />
						<OrbitControls
							ref={orbitControlsRef}
							target={new THREE.Vector3(-1.328, 1.131, -0.352)}
							maxPolarAngle={Math.PI / 2}
							minDistance={2}
							maxDistance={10}
						/>
						<ZoomHandler isBookOpen={isBookOpen} orbitControlsRef={orbitControlsRef} />
					</Canvas>
				</animated.div> */}

				{!showFlipBook ? (
					<div className="flex justify-center items-center h-full">
						<span className="loading loading-lg"></span>
					</div>
				) : (
					<animated.div
						className="flipbook-container"
						style={{
							opacity: opacity,
							backgroundColor: '#a52a2a',
							boxShadow: '0 4px 8px rgba(0,0,0,0.2)'
						}}
					>
						<FlipBook />
					</animated.div>
				)}
			</div>
			<div className="flex base:hidden">
				<WarningMessage />
			</div>
		</div>
	);
}

// function ZoomHandler({
// 	isBookOpen,
// 	orbitControlsRef
// }: {
// 	isBookOpen: boolean;
// 	orbitControlsRef: React.MutableRefObject<any>;
// }) {
// 	useFrame(({ camera }) => {
// 		if (isBookOpen) {
// 			camera.position.lerp(new THREE.Vector3(-1.328, 1.5, -0.352), 0.1);
// 			if (orbitControlsRef.current) {
// 				orbitControlsRef.current.target.lerp(new THREE.Vector3(-1.328, 1.131, -0.352), 0.1);
// 				orbitControlsRef.current.update();
// 			}
// 		}
// 	});
// 	return null;
// }
interface DropZoneProps {
	location: Location;
	pageNumber: number;
	journal: Record<number, StickerLocations[]>;
	setJournal: React.Dispatch<React.SetStateAction<Record<number, StickerLocations[]>>>;
	isEditMode: boolean;
	isFollowing?: boolean;
}

interface StickerItem {
	id: string;
}

const DropZone: FC<DropZoneProps> = ({ location, journal, setJournal, pageNumber, isEditMode, isFollowing }) => {
	const stickers = useSelector((state: RootState) => state.profile.stickers);
	const friendsStickers = useSelector((state: RootState) => state.profile.friendsStickers);

	const [{ canDrop, isOver }, drop] = useDrop(() => ({
		accept: ItemTypes.STICKER,
		drop: (item: StickerItem) => {
			setJournal((prev) => {
				const updatedLocations = [
					...(prev[pageNumber] || []).filter((loc) => loc.location !== location),
					{ location, stickerId: item.id } as StickerLocations
				];
				return { ...prev, [pageNumber]: updatedLocations };
			});
			return { name: 'DropZone' };
		},
		collect: (monitor) => ({
			isOver: monitor.isOver(),
			canDrop: monitor.canDrop()
		})
	}));

	const isActive = canDrop && isOver;

	const handleRemoveSticker = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		e.stopPropagation();

		setJournal((prev) => {
			const updatedLocations = (prev[pageNumber] || []).filter((loc) => loc.location !== location);
			return { ...prev, [pageNumber]: updatedLocations };
		});
	};

	const existingSticker = (journal[pageNumber] || []).find((loc) => loc.location === location);
	const stickerUrl = existingSticker
		? !isFollowing
			? stickers.find((sticker) => sticker._id === existingSticker.stickerId)?.stickerUrl
			: friendsStickers.find((sticker) => sticker._id === existingSticker.stickerId)?.stickerUrl
		: null;

	const dropRef = useRef<HTMLDivElement>(null);
	drop(dropRef);

	return (
		<div
			ref={dropRef}
			className={`relative h-[160px] w-[240px] m-2 text-center pt-10 text-xs border ${isEditMode ? 'border-black border-dashed' : ''}`}
		>
			{isEditMode && !existingSticker && (
				<span className="text-black">{isActive ? 'Release to drop' : 'Drag a sticker here'}</span>
			)}
			{existingSticker && stickerUrl && (
				<>
					{isEditMode && (
						<button
							type="button"
							onMouseDownCapture={(e) => {
								e.preventDefault();
								e.stopPropagation();
								handleRemoveSticker(e);
							}}
							className="absolute top-[-10px] right-[-10px] w-6 h-6 rounded-full bg-red-500 z-50"
						>
							<IconMinus className="text-white" />
						</button>
					)}
					<img
						src={stickerUrl}
						alt="Dropped Sticker"
						className="object-cover"
						style={{
							position: 'absolute',
							top: '50%',
							left: '50%',
							transform: 'translate(-50%, -50%)'
						}}
					/>
				</>
			)}
		</div>
	);
};

const DraggableSticker: React.FC<{ toggleDrawer: () => void; src: string; name: string; id: string }> = ({
	toggleDrawer,
	src,
	name,
	id
}) => {
	const [{ isDragging }, drag] = useDrag(() => ({
		type: ItemTypes.STICKER,
		item: () => {
			toggleDrawer();
			return { name, src, id };
		},
		collect: (monitor) => ({
			isDragging: monitor.isDragging()
		})
	}));

	const opacity = isDragging ? 0.4 : 1;

	const dragRef = useRef<HTMLDivElement>(null);
	drag(dragRef);

	return (
		// <div ref={dragRef} style={{ opacity }} className="w-full flex justify-center items-center">
		// 	<div className="flex flex-col items-center justify-center  w-[160px] h-[195px]">
		// 		<div className=" h-32 w-32 mt-4 flex justify-center items-center">
		// 			<img src={src} alt={name} className="w-28 h-28 object-cover" />
		// 		</div>
		// 	</div>
		// </div>
		<div ref={dragRef} style={{ opacity }} className="relative flex w-36 h-44 items-center justify-center">
			<div className="sticker-background bg-lt-cream dark:bg-lt-dark-primary-bg w-36 h-44 flex items-center justify-center">
				<img
					style={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)'
					}}
					src={src}
					alt={name}
					className="object-cover"
				/>
				<div className="sticker-corner top-right bg-lt-lightCream dark:bg-lt-dark-secondary-bg "></div>
				<div className="sticker-corner bottom-left"></div>
			</div>
			<span className="absolute bottom-0 left-0 right-0 text-center text-sm text-lt-beige dark:text-lt-white font-semibold">
				{name}
			</span>
		</div>
	);
};

interface FlipbookProps {
	isFollowing?: boolean;
}

export const FlipBook: React.FC<FlipbookProps> = ({ isFollowing }) => {
	const { userId } = useParams<{ userId: string }>();
	const dispatch = useDispatch();
	const myTables = useSelector((state: RootState) => state.myTables);
	const [isOpen, setIsOpen] = useState(false);
	const [isEditMode, setIsEditMode] = useState(false);
	const profile = useSelector((state: RootState) => state.profile.profile);
	const [journal, setJournal] = useState<Record<number, StickerLocations[]>>({});
	const [getMyTablesReq] = useLazyGetTablesQuery();
	const [isMyTablesLoading, setIsMyTablesLoading] = useState(false);
	const [getMyStickersRequest, { isLoading: isStickersLoading }] = useLazyGetMyStickersQuery();
	const [updateMyJournalRequest, { isLoading: isUpdateLoading }] = useUpdateMyJournalMutation();
	const [getFriendsJournalRequest, { isLoading: isFriendsJournalLoading }] = useLazyGetFriendsJournalQuery();
	const [friendsJournal, setFriendsJournal] = useState<FriendsJournalResponse>();
	const [friendsJournalData, setFriendsJournalData] = useState<Record<number, StickerLocations[]>>({});
	const myStickers = useSelector((state: RootState) => state.profile.stickers);

	// Sort myStickers alphabetically by title
	const sortedStickers = [...myStickers].sort((a, b) => a.title.localeCompare(b.title));

	useEffect(() => {
		const fetchMyTables = async () => {
			setIsMyTablesLoading(true);
			try {
				const totalPages = myTables.pagination.totalPages;
				for (let i = 1; i <= totalPages; i++) {
					if (myTables.pagination.page < totalPages) {
						const response = await getMyTablesReq({
							limit: 10,
							page: i,
							query: ''
						}).unwrap();
						if (response) {
							dispatch(
								getTablesSuccess({
									tables: response.tables,
									query: '',
									pagination: {
										page: response.pagination.page,
										totalPages: response.pagination.totalPages,
										totalTables: response.pagination.totalTables
									}
								})
							);
						}
					}
				}
				setIsMyTablesLoading(false);
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			}
		};
		if (isFollowing || userId || myTables.pagination.page >= myTables.pagination.totalPages) return;
		fetchMyTables();
	}, [dispatch, getMyTablesReq, myTables.pagination.totalTables]);

	const filteredTables = myTables.myTables.filter((table) => table.isTablePublic === true).reverse();

	useEffect(() => {
		const fetchStickers = async () => {
			try {
				const response = await getMyStickersRequest().unwrap();
				if (response.data) {
					dispatch(getMyStickersSuccess(response.data));
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			}
		};
		fetchStickers();
	}, [dispatch, getMyStickersRequest]);

	useEffect(() => {
		const fetchFriendsJournal = async () => {
			try {
				if (!userId) return;
				const response = await getFriendsJournalRequest(userId).unwrap();
				if (response) {
					setFriendsJournal(response);
					if (response.data.journal && response.data.journal.length > 0) {
						const convertToRecord = (friendJournalPages: JournalPage[]): Record<number, StickerLocations[]> => {
							return friendJournalPages.reduce(
								(acc, page) => {
									acc[page.pageNumber] = page.stickerLocations;
									return acc;
								},
								{} as Record<number, StickerLocations[]>
							);
						};

						setFriendsJournalData(convertToRecord(response.data.journal));
					} else {
						setFriendsJournalData({});
					}
				}
			} catch (error) {
				if (isCustomErrorResponse(error)) {
					toast.error(error.data.message);
				} else if (error instanceof Error) {
					toast.error(error.message);
				} else {
					toast.error('An unknown error occurred. Please refresh the page.');
				}
			}
		};
		if (isFollowing) {
			fetchFriendsJournal();
		}
	}, [dispatch, getFriendsJournalRequest, isFollowing]);

	useEffect(() => {
		if (profile.myJournal) {
			const initialJournal: Record<number, StickerLocations[]> = {};
			profile.myJournal.forEach((page) => {
				initialJournal[page.pageNumber] = page.stickerLocations;
			});
			setJournal(initialJournal);
		}
	}, [profile.myJournal]);

	const toggleDrawer = () => {
		setIsOpen((prev) => !prev);
	};

	const saveStickers = async () => {
		if (profile.myJournal && Object.keys(journal).length === profile.myJournal.length) {
			let hasChanges = false;
			for (const page of profile.myJournal) {
				const newPage = journal[page.pageNumber];
				if (!newPage || newPage.length !== page.stickerLocations.length) {
					hasChanges = true;
					break;
				}
				for (const location of newPage) {
					const oldLocation = page.stickerLocations.find((loc) => loc.location === location.location);
					if (!oldLocation || oldLocation.stickerId !== location.stickerId) {
						hasChanges = true;
						break;
					}
				}
			}
			if (!hasChanges) {
				toast.success('No changes to save');
				return;
			}
		}
		try {
			const pages = Object.keys(journal).map((pageNumber) => ({
				pageNumber: parseInt(pageNumber),
				stickerLocations: journal[pageNumber as unknown as number]
			}));
			const response = await updateMyJournalRequest(pages).unwrap();

			if (response) {
				dispatch(getMyJournalSuccess(response.data));
				if (response.achievements.length > 0) {
					dispatch(achievementEarnedSuccess(response.achievements));
					response.achievements.forEach((achievement: IAchievement) => {
						toast(
							<AchievementToast
								title={achievement.title}
								description={achievement.description}
								threshold={achievement.threshold}
							/>
						);
					});
				}
				toast.success(response.message);
			}
		} catch (error) {
			if (isCustomErrorResponse(error)) {
				toast.error(error.data.message);
			} else if (error instanceof Error) {
				toast.error(error.message);
			} else {
				toast.error('An unknown error occurred. Please try again later.');
			}
		}
	};

	return (
		<DndProvider backend={HTML5Backend}>
			<div className={`flex justify-center items-center w-full ${isFollowing ? 'py-5' : ' absolute left-0'}`}>
				{!isFollowing && (
					<>
						<Drawer
							open={isOpen}
							onClose={toggleDrawer}
							direction="right"
							className="bg-lt-lightCream dark:bg-lt-dark-secondary-bg  w-2/5 overflow-y-auto"
						>
							{isStickersLoading ? (
								<div className="w-full h-full flex justify-center items-center">
									<span className="loading loading-spinner loading-xs"></span>
								</div>
							) : (
								<div className="h-auto grid grid-cols-3 gap-8 p-4 place-items-center">
									{sortedStickers.map((sticker, index) => (
										<DraggableSticker
											key={index}
											toggleDrawer={toggleDrawer}
											id={sticker._id}
											src={sticker.stickerUrl}
											name={sticker.title}
										/>
									))}
								</div>
							)}
						</Drawer>
					</>
				)}

				<div className="base:w-full lg:min-w-[1050px] xl:min-w-[1090px] h-full flex flex-col justify-center items-end gap-2 2xl:gap-20">
					{!isFollowing && filteredTables.length > 0 && (
						<>
							<div className="hidden base:flex h-12 gap-2 mt-1 mr-1">
								{isEditMode && (
									<Button type="button" variant="btn-secondary-light" onClick={toggleDrawer}>
										My Stickers
									</Button>
								)}
								<Button
									disabled={isUpdateLoading}
									type="button"
									variant="btn-secondary-light"
									onClick={() => {
										setIsEditMode((prev) => !prev);
										if (isEditMode) {
											saveStickers();
										}
									}}
								>
									{isUpdateLoading ? (
										<div className="w-full h-full flex justify-center items-center">
											<span className="loading loading-spinner loading-xs"></span>
										</div>
									) : isEditMode ? (
										'Save'
									) : (
										'Edit'
									)}
								</Button>
							</div>
							<div className="hidden md:flex md:mt-5 md:px-4 text-lt-light-secondary-text dark:text-lt-lightCream base:hidden">
								<IconInfoCircle />
								<span>Please switch to a larger device to view the stickers.</span>
							</div>
						</>
					)}

					{!isMyTablesLoading && !isFollowing && !userId && filteredTables.length === 0 && (
						<div className="w-full h-screen flex justify-center items-center">
							<h6 className="font-bold text-2xl">
								You need to create a table and make it public to view it in your journal.
							</h6>
						</div>
					)}
					{isMyTablesLoading ? (
						<div className="w-full h-full flex justify-center items-center">
							<span className="loading loading-spinner loading-xs"></span>
						</div>
					) : (
						<>
							{!isFollowing && !userId && filteredTables.length > 0 && (
								<HTMLFlipBook
									maxHeight={1000}
									maxWidth={1200}
									minHeight={470}
									minWidth={300}
									width={800}
									height={1000}
									className="my-flipbook flex w-full base:max-h-[600px] lg:max-h-[670px] max-w-[1100px]"
									style={{ margin: 'auto', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }}
									startPage={0}
									size="stretch"
									maxShadowOpacity={0.5}
									showCover={false}
									mobileScrollSupport={true}
									useMouseEvents={true}
									drawShadow={true}
									flippingTime={1000}
									usePortrait={true}
									startZIndex={0}
									autoSize={true}
									clickEventForward={true}
									swipeDistance={30}
									showPageCorners={true}
									disableFlipByClick={false}
								>
									{filteredTables.map((table, index) => (
										<div key={index} className="notebook-page ">
											<div className="page-content p-0 lg:p-5 flex flex-col gap-2 w-full">
												<h1 className="text-2xl text-center font-bold dark:text-black">{table.title}</h1>
												<div className="flex">
													<Table componentUsed="myJournal" newTableData={table} />

													<div className="w-7/12 flex flex-col items-start">
														<div className="flex w-full">
															<div className="base:w-10/12 lg:w-7/12">
																<div className="h-2/6 grid grid-cols-1 gap-2.5 px-1">
																	{table.dataRanges.map((dataRange, index) => (
																		<div key={index} className="flex items-center justify-start">
																			<div
																				style={{ backgroundColor: dataRange.hexColorCode }}
																				className="min-w-5 min-h-5 rounded-sm"
																			></div>
																			<span className="text-xs ml-2 dark:text-lt-light-secondary-text whitespace-nowrap overflow-hidden text-ellipsis">
																				{dataRange.range} {dataRange?.unit}
																			</span>
																		</div>
																	))}
																</div>
															</div>
															<div className="hidden w-6/12 base:flex items-center">
																<DropZone
																	journal={journal}
																	setJournal={setJournal}
																	location={Location.TOP}
																	pageNumber={index + 1}
																	isEditMode={isEditMode}
																	isFollowing={isFollowing}
																/>
															</div>
														</div>
														<div className="hidden base:flex h-2/6 w-full">
															<DropZone
																journal={journal}
																setJournal={setJournal}
																location={Location.MIDDLE_LEFT}
																pageNumber={index + 1}
																isEditMode={isEditMode}
																isFollowing={isFollowing}
															/>
															<DropZone
																journal={journal}
																setJournal={setJournal}
																location={Location.MIDDLE_RIGHT}
																pageNumber={index + 1}
																isEditMode={isEditMode}
																isFollowing={isFollowing}
															/>
														</div>
														<div className="hidden base:flex h-2/6 w-full">
															<DropZone
																journal={journal}
																setJournal={setJournal}
																location={Location.BOTTOM_LEFT}
																pageNumber={index + 1}
																isEditMode={isEditMode}
																isFollowing={isFollowing}
															/>
															<DropZone
																journal={journal}
																setJournal={setJournal}
																location={Location.BOTTOM_RIGHT}
																pageNumber={index + 1}
																isEditMode={isEditMode}
																isFollowing={isFollowing}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									))}
								</HTMLFlipBook>
							)}
						</>
					)}

					{isFollowing && userId && friendsJournal?.data && friendsJournal?.data.tables.length > 0 && (
						<>
							{isFriendsJournalLoading ? (
								<div className="w-full h-full flex justify-center items-center">
									<span className="loading loading-spinner loading-xs"></span>
								</div>
							) : (
								<HTMLFlipBook
									width={800}
									height={1000}
									minHeight={500}
									minWidth={300}
									maxHeight={1000}
									maxWidth={800}
									className="my-flipbook flex w-full base:max-h-[550px] lg:max-h-[670px] max-w-[1100px]"
									style={{ margin: 'auto', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }}
									startPage={0}
									size="stretch"
									maxShadowOpacity={0.5}
									showCover={false}
									mobileScrollSupport={true}
									useMouseEvents={true}
									drawShadow={true}
									flippingTime={1000}
									usePortrait={true}
									startZIndex={0}
									autoSize={true}
									clickEventForward={true}
									swipeDistance={30}
									showPageCorners={true}
									disableFlipByClick={false}
								>
									{friendsJournal?.data.tables.map((table, index) => (
										<div key={index} className="notebook-page">
											<div className="page-content p-0 lg:p-5 flex flex-col gap-2 w-full">
												<h1 className="text-2xl text-center font-bold dark:text-black">{table.title}</h1>
												<div className="flex">
													<Table componentUsed="myJournal" newTableData={table} />

													<div className="w-7/12 flex flex-col items-start">
														<div className="flex w-full">
															<div className="base:w-10/12 lg:w-7/12">
																<div className="h-2/6 grid grid-cols-1 gap-2.5 px-1">
																	{table.dataRanges.map((dataRange, index) => (
																		<div key={index} className="flex items-center justify-start">
																			<div
																				style={{ backgroundColor: dataRange.hexColorCode }}
																				className="min-w-5 min-h-5 rounded-sm"
																			></div>
																			<span className="text-xs ml-2 dark:text-lt-light-secondary-text whitespace-nowrap overflow-hidden text-ellipsis">
																				{dataRange.range} {dataRange?.unit}
																			</span>
																		</div>
																	))}
																</div>
															</div>
															<div className="hidden w-6/12 base:flex items-center">
																<DropZone
																	journal={friendsJournalData}
																	setJournal={setJournal}
																	location={Location.TOP}
																	pageNumber={index + 1}
																	isEditMode={isEditMode}
																	isFollowing={isFollowing}
																/>
															</div>
														</div>
														<div className="hidden base:flex h-2/6 w-full">
															<DropZone
																journal={friendsJournalData}
																setJournal={setJournal}
																location={Location.MIDDLE_LEFT}
																pageNumber={index + 1}
																isEditMode={isEditMode}
																isFollowing={isFollowing}
															/>
															<DropZone
																journal={friendsJournalData}
																setJournal={setJournal}
																location={Location.MIDDLE_RIGHT}
																pageNumber={index + 1}
																isEditMode={isEditMode}
																isFollowing={isFollowing}
															/>
														</div>
														<div className="hidden base:flex h-2/6 w-full">
															<DropZone
																journal={friendsJournalData}
																setJournal={setJournal}
																location={Location.BOTTOM_LEFT}
																pageNumber={index + 1}
																isEditMode={isEditMode}
																isFollowing={isFollowing}
															/>
															<DropZone
																journal={friendsJournalData}
																setJournal={setJournal}
																location={Location.BOTTOM_RIGHT}
																pageNumber={index + 1}
																isEditMode={isEditMode}
																isFollowing={isFollowing}
															/>
														</div>
													</div>
												</div>
											</div>
										</div>
									))}
								</HTMLFlipBook>
							)}
						</>
					)}
				</div>
			</div>
		</DndProvider>
	);
};
const WarningMessage: React.FC = () => {
	return (
		<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 text-white text-center p-4">
			<div>
				<h1 className="text-2xl font-bold">Insufficient Screen Resolution</h1>
				<p className="mt-2">Please switch to a larger device to view this page.</p>
				<Link className="text-lt-beige" to="/dashboard/my-tables">
					Go back to my tables
				</Link>
			</div>
		</div>
	);
};

export default MyJournal;
