import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Logo from '/src/assets/logo.svg';

export default function RefundPolicyEn() {
	const isCartDomain = window.location.hostname.includes('cart.');
	const pathname = window.location.pathname;
	const parts = pathname.split('/');
	const language = parts[1];

	return (
		<div>
			<Helmet>
				<title>Life on Track - Refund Policy</title>
				<meta name="description" content="Refund policy of Life on Track." />
			</Helmet>
			<header className="sticky top-0 z-50 w-full h-[70px] px-4 md:px-12 flex items-center justify-between border-b  bg-lt-lightCream dark:bg-lt-dark-secondary-bg">
				<div className="flex justify-start items-center md:gap-2 w-4/12 md:w-1/4">
					<img src={Logo} alt="logo" className="w-8 h-8 md:w-10 md:h-10" />
					{isCartDomain ? (
						<a
							href="https://lifeontrack.co"
							className="text-lt-beige dark:text-white font-extrabold text-sm sm:text-2xl"
						>
							Life on Track
						</a>
					) : (
						<Link to="/" className="text-lt-beige dark:text-white font-extrabold text-sm sm:text-2xl">
							Life on Track
						</Link>
					)}
				</div>
				<div className="hidden md:flex justify-between base:justify-center base:gap-10 md:w-1/2">
					<Link
						to="/en/privacy-policy"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Privacy Policy
					</Link>
					<Link
						to="/en/terms-of-service"
						className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
					>
						Terms of Service
					</Link>
					{isCartDomain ? (
						<a
							href="https://lifeontrack.co"
							className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
						>
							Home
						</a>
					) : (
						<Link
							to="/"
							className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
						>
							Home
						</Link>
					)}
				</div>
				<div className="navbar w-1/3 flex justify-center md:hidden">
					<div className="navbar-center md:hidden">
						<ul className="menu menu-md menu-horizontal px-1">
							<li>
								<details>
									<summary className="text-lt-beige dark:text-[#5D4037] active:bg-lt-lightCream active:text-lt-beige dark:active:bg-transparent">
										Menu
									</summary>
									<ul className="flex flex-col text-xs sm:text-base font-semibold justify-between text-black dark:text-white dark:bg-lt-dark-popupBackground  divide-y divide-lt-beige dark:divide-[#6E6E40] bg-lt-lightCream px-0 ">
										<li className="px-4">
											<Link
												to="/en/privacy-policy"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Privacy Policy
											</Link>
										</li>
										<li className="px-4">
											<Link
												to="/en/terms-of-service"
												className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
											>
												Terms of Service
											</Link>
										</li>
										<li className="px-4">
											{isCartDomain ? (
												<a
													href="https://lifeontrack.co"
													className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
												>
													Home
												</a>
											) : (
												<Link
													to="/"
													className="flex text-center items-center text-xs sm:text-base md:text-lg base:text-xl font-bold dark:text-white rounded-xl text-black hover:text-lt-beige"
												>
													Home
												</Link>
											)}
										</li>
									</ul>
								</details>
							</li>
						</ul>
					</div>
				</div>
				<div className="flex justify-end gap-1 sm:gap-4 text-xs sm:text-base md:text-xl w-2/12 md:w-1/4">
					<Link
						className={`${language === 'en' ? 'text-lt-beige dark:text-[#5D4037]' : 'text-black dark:text-white'} hover:text-lt-beige}`}
						to="/en/refund-policy"
					>
						EN
					</Link>
					/
					<Link
						className={`${language === 'tr' ? 'text-lt-beige dark:text-[#5D4037]' : 'text-black dark:text-white'} hover:text-lt-beige}`}
						to="/tr/refund-policy"
					>
						TR
					</Link>
				</div>
			</header>
			<div className="max-w-3xl mx-auto p-6 mt-5  h-screen overflow-y-auto pb-40">
				<h1 className="text-xl text-center font-bold mb-4">CANCELLATION, REFUND AND DELIVERY CONDITIONS</h1>
				<p className="mb-6">
					This Cancellation and Refund Policy (“<strong>Policy</strong>”) contains the cancellation and refund
					conditions for the products and services we offer to our Users as Kaan Mert (“Kaan Software Studio”).
				</p>

				<ol className="list-decimal list-inside mb-6">
					<li className="mb-4">
						This Policy is an integral part of the Distance Sales Agreement/Terms and Conditions ("
						<strong>Agreement</strong>") and the definitions in the Agreement are also valid in the Policy.
					</li>
					<li className="mb-4">
						If you purchase our Products/Services through Life on Track, you are deemed to have accepted the Agreement.
					</li>
					<li className="mb-4">
						Kaan Software Studio offers Products/Services through Life on Track that fall within the scope of “contracts
						for services whose execution is started with the approval of the consumer before the expiration of the right
						of withdrawal period” specified in subparagraph (h) of paragraph 1 of Article 15 titled “Exceptions to the
						Right of Withdrawal” of the Distance Agreements Regulation and “contracts for services performed instantly
						in electronic media and contracts for intangible goods delivered to the consumer instantly” specified in
						subparagraph (ğ) of paragraph 1 of the same article. For this reason, you cannot benefit from the right of
						withdrawal in accordance with the Regulation in terms of the Product/Service you have purchased. However,
						you can always cancel your membership. In case of cancellation, it will be possible to continue to benefit
						from the Products/Services until the end of the term already paid for.
					</li>
					<li className="mb-4">
						The User accepts that the service offered is in the category of “electronically deliverable product” and
						therefore cannot be returned. No physical delivery will be made due to the nature of the Products/Services.
					</li>
				</ol>
			</div>
		</div>
	);
}
