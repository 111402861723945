export interface Month {
	name: string;
	dayCount: number;
	firstLetter: string;
}

export interface TodaysIndex {
	day: string;
	month: string;
	year: string;
}

const currentYear = new Date().getFullYear();

export const months: Month[] = [
	{
		name: 'January',
		firstLetter: 'J',
		dayCount: 31
	},
	{
		name: 'February',
		firstLetter: 'F',
		dayCount: currentYear % 4 === 0 ? 29 : 28
	},
	{
		name: 'March',
		firstLetter: 'M',
		dayCount: 31
	},
	{
		name: 'April',
		firstLetter: 'A',
		dayCount: 30
	},
	{
		name: 'May',
		firstLetter: 'M',
		dayCount: 31
	},
	{
		name: 'June',
		firstLetter: 'J',
		dayCount: 30
	},
	{
		name: 'July',
		firstLetter: 'J',
		dayCount: 31
	},
	{
		name: 'August',
		firstLetter: 'A',
		dayCount: 31
	},
	{
		name: 'September',
		firstLetter: 'S',
		dayCount: 30
	},
	{
		name: 'October',
		firstLetter: 'O',
		dayCount: 31
	},
	{
		name: 'November',
		firstLetter: 'N',
		dayCount: 30
	},
	{
		name: 'December',
		firstLetter: 'D',
		dayCount: 31
	}
];

export interface ITable {
	_id?: string;
	title: string;
	icon: string;
	isTablePublic: boolean;
	dataRanges: IDataRange[];
	notes?: string;
	img?: string;
}

export interface IDataRange {
	_id?: string;
	hexColorCode: string;
	range: string;
	unit?: string;
	activeDays?: IActiveDay[];
}

export interface IActiveDay {
	_id?: string;
	dayIndex: number;
	monthIndex: number;
	hexColorCode?: string;
}
