import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './views/Home/Home';
import Register from './views/Auth/Register';
import axios from 'axios';
import { Toaster } from 'react-hot-toast';
import EmailVerification from './views/Auth/EmailVerification';
import { VerifyEmail } from './views/Auth/VerifyEmail';
import ForgotPassword from './views/Auth/ForgotPassword';
import ResetPassword from './views/Auth/ResetPassword';
import TokenRefreshProvider from './provider/TokenRefreshProvider';
import { AuthLayout } from './views/Auth/Layout/AuthLayout';
import { DashboardLayout } from './views/Dashboard/Layout/DashboardLayout';
import HomeLayout from './views/Home/Layout/HomeLayout';
import MyTables from './views/Dashboard/MyTables/MyTables';

import Profile from './views/Dashboard/Profile/Profile';
import Shop from './views/Dashboard/Shop/Shop';
import Social from './views/Dashboard/Social/Social';

import NotFound from './views/NotFound/NotFound';
import TableDetails from './views/Dashboard/MyTables/TableDetails/TableDetails';
import { ToastContainer } from 'react-toastify';
import MyJournal from './views/Dashboard/MyJournal/MyJournal';
// import Whitelist from './views/Auth/Whitelist';
import Help from './views/Home/Help/Help';
import Blogs from './views/Home/Blogs/Blogs';
import Blog from './views/Home/Blogs/Blog/Blog';
import FAQs from './views/Home/Help/FAQs/FAQs';
import GettingStarted from './views/Home/Help/GettingStarted/GettingStarted';
import LifeTracker from './views/Home/Help/GettingStarted/LifeTracker/LifeTracker';
import YourCart from './views/YourCart/YourCart';
import AboutUs from './views/Home/AboutUs/AboutUs';
import PrivacyPolicyTr from './views/Home/PrivacyPolicyTr/PrivacyPolicyTr';
import PrivacyPolicyEn from './views/Home/PrivacyPolicyEn/PrivacyPolicyEn';
import RefundPolicyEn from './views/Home/RefundPolicyEn/RefundPolicyEn';
import RefundPolicyTr from './views/Home/RefundPolicyTr/RefundPolicyTr';
import TermsAndConditionsEn from './views/Home/TermsAndConditionsEn/TermsAndConditionsEn';
import TermsAndConditionsTr from './views/Home/TermsAndConditionsTr/TermsAndConditionsTr';
import Contact from './views/Home/Contact/Contact';
import Test from './views/test';
import Login from './views/Auth/Login';
import Achievements from './views/Dashboard/Achievements/Achievements';

function App() {
	const env = import.meta.env.VITE_ENV;
	axios.defaults.baseURL = env.NODE_ENV === 'development' ? env.VITE_API_URL_TEST : env.VITE_API_URL_PROD;
	axios.defaults.withCredentials = true;

	return (
		<BrowserRouter>
			<TokenRefreshProvider>
				<Toaster position="top-right" toastOptions={{ duration: 2500 }} />
				<ToastContainer />
				<Routes>
					{/* Home */}

					<Route element={<HomeLayout />}>
						{env !== 'cart' && (
							<>
								<Route path="/" element={<Home />} />
								<Route path="/contact" element={<Contact />} />
								{/* <Route path="/tr/refund-policy" element={<RefundPolicyTr />} /> */}
								<Route path="/help" element={<Help />} />
								<Route path="/about-us" element={<AboutUs />} />
								<Route path="/blogs" element={<Blogs />} />
								<Route path="/blog/:id" element={<Blog />}></Route>
								{/* <Route path="/help/how-tos" element={<HowTos />}></Route>
							<Route path="/help/how-tos/create-delete-table" element={<CreateAndDeleteTable />}></Route>
							<Route path="/help/how-tos/design-journal" element={<DesignYourJournal />}></Route>
							<Route path="/help/how-tos/daily-activities" element={<EnterDailyActivities />}></Route>
							<Route path="/help/how-tos/manage-friends" element={<ManageFriends />}></Route> */}
								<Route path="/help/faqs" element={<FAQs />}></Route>
								<Route path="/help/getting-started" element={<GettingStarted />}></Route>
								<Route path="/help/getting-started/life-tracker" element={<LifeTracker />}></Route>
							</>
						)}
						<Route path="/en/refund-policy" element={<RefundPolicyEn />} />
						<Route path="/tr/refund-policy" element={<RefundPolicyTr />} />
						<Route path="/en/terms-of-service" element={<TermsAndConditionsEn />} />
						<Route path="/tr/terms-of-service" element={<TermsAndConditionsTr />} />
						<Route path="/en/privacy-policy" element={<PrivacyPolicyEn />} />
						<Route path="/tr/privacy-policy" element={<PrivacyPolicyTr />} />
					</Route>

					{/* Auth */}
					<Route element={<AuthLayout />}>
						<Route path="/auth/register" element={<Register />} />
						<Route path="/auth/login" element={<Login />} />
						<Route path="/auth/email-verification" element={<EmailVerification />} />
						<Route path="/auth/verify-email" element={<VerifyEmail />} />
						<Route path="/auth/forgot-password" element={<ForgotPassword />}></Route>
						<Route path="/auth/reset-password" element={<ResetPassword />}></Route>
						{/* <Route path="/auth/whitelist" element={<Whitelist />}></Route> */}
					</Route>

					{/* Dashboard */}
					{env !== 'cart' && (
						<Route element={<DashboardLayout />}>
							<Route path="/dashboard/my-tables" element={<MyTables />}></Route>
							<Route path="/dashboard/my-tables/:tableId" element={<TableDetails />} />
							<Route path="/dashboard/my-journal" element={<MyJournal />}></Route>
							<Route path="/dashboard/profile" element={<Profile />}></Route>
							<Route path="/dashboard/profile/:userId" element={<Profile />}></Route>
							<Route path="/dashboard/shop" element={<Shop />}></Route>
							<Route path="/dashboard/social" element={<Social />}></Route>
							<Route path="/dashboard/achievements" element={<Achievements />}></Route>
						</Route>
					)}

					{env === 'cart' && <Route path="/your-cart" element={<YourCart />} />}
					<Route path="/payment-page" element={<Test />} />
					<Route path="*" element={<NotFound />} />
				</Routes>
			</TokenRefreshProvider>
		</BrowserRouter>
	);
}

export default App;
