import { Link } from 'react-router-dom';

export default function Footer() {
	const handleClick = () => {
		window.scrollTo(0, 0);
	};
	return (
		<footer className="flex justify-center h-[375px] sm:h-[450px] bg-gradient-to-b w-full from-lt-lightCream to-[#8E4600] dark:from-[#121212] dark:to-lt-dark-popupBackground">
			<div className="flex flex-col justify-between w-full max-w-[1000px] px-4 base:px-0 pb-20 sm:pb-0">
				<div className="text-center">
					<a
						href="mailto:contact@lifeontrack.co"
						className="text-lt-dark-inputBorder dark:text-lt-dark-inputPlaceholder dark:hover:text-[#8E4600] hover:text-lt-beige transition-colors duration-300 text-2xl"
					>
						contact@lifeontrack.co
					</a>
				</div>
				<div className="flex justify-around sm:justify-center sm:mx-auto gap-4 sm:gap-20 md:gap-40  pt-6">
					<div>
						<h2 className="text-[#8E4600] font-bold text-lg sm:text-2xl">Social</h2>
						<div className="mt-4">
							<ul className="flex gap-2 flex-col text-sm sm:text-xl text-[#333333] dark:text-[#BDBDBD] font-semibold">
								<li>
									<a
										target="_blank"
										rel="noreferrer noopener"
										className="hover:underline"
										href="https://twitter.com/Kaanmrttt"
									>
										Twitter
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div>
						<h2 className="text-[#8E4600] font-bold text-lg sm:text-2xl">Learn More</h2>
						<div className="mt-4">
							<ul className="text-[#333333] dark:text-[#BDBDBD] text-sm sm:text-xl font-semibold flex flex-col gap-2">
								<li>
									<Link onClick={handleClick} className="hover:underline" to="/about-us">
										About Us
									</Link>
								</li>
								<li>
									<Link onClick={handleClick} className="hover:underline" to="/blogs">
										Blog
									</Link>
								</li>
								<li>
									<Link onClick={handleClick} className="hover:underline" to="/help">
										Support
									</Link>
								</li>
								<li>
									<Link onClick={handleClick} to="/contact" className="hover:underline">
										Contact
									</Link>
								</li>
								<li>
									<a className="hover:underline" href="mailto:contact@lifeontrack.co">
										Email Us
									</a>
								</li>
							</ul>
						</div>
					</div>
					<div>
						<h2 className="text-[#8E4600] font-bold text-lg sm:text-2xl">Legal</h2>
						<div className="mt-4">
							<ul className="flex gap-2 flex-col text-sm sm:text-xl text-[#333333] dark:text-[#BDBDBD] font-semibold">
								<li>
									<Link onClick={handleClick} to="/en/privacy-policy" className="hover:underline">
										Privacy Policy
									</Link>
								</li>
								<li>
									<Link onClick={handleClick} to="/en/terms-of-service" className="hover:underline">
										Terms of Service
									</Link>
								</li>
								<li>
									<Link onClick={handleClick} to="/en/refund-policy" className="hover:underline">
										Refund Policy
									</Link>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="flex flex-col sm:flex-row items-start justify-between border-t py-3 sm:py-5 border-[#C8A165] dark:border-[#6E6E40]">
					<a className="text-[#C8A165] text-xl sm:text-3xl font-bold dark:text-white" href="">
						Life on Track
					</a>

					<div className="flex items-end h-full">
						<h6 className="text-[#333333] dark:text-[#BDBDBD] text-2xs sm:text-xs md:text-base  font-semibold text-end">
							{' '}
							© 2024 Life on Track. All rights reserved.
						</h6>
					</div>
				</div>
			</div>
		</footer>
	);
}
