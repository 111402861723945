import { useDispatch, useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import { RootState } from '../../../states/store';
import { useEffect } from 'react';
import { loginSuccess } from '../../../states/auth/authSlice';

export const AuthLayout = () => {
	const dispatch = useDispatch();
	const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated);
	const profile = useSelector((state: RootState) => state.profile.profile);

	useEffect(() => {
		if (profile._id && profile._id !== '') {
			dispatch(loginSuccess({ email: profile.email }));
		}
	}, [dispatch, profile]);

	if (isAuthenticated) {
		if (window.location.hostname.includes('cart.')) {
			return <Navigate to="/payment-page" replace />;
		}
		return <Navigate to="/dashboard/my-tables" replace />;
	}

	return <Outlet />;
};
