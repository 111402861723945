import { createApi } from '@reduxjs/toolkit/query/react';
import { JournalPage } from '../../views/Dashboard/MyJournal/ItemTypes';
import { baseQueryWithReauth } from './customBaseQuery';
import { IAchievement } from '../../types/achievement.types';

interface MyJournalResponse {
	data: JournalPage[];
	achievements: IAchievement[];
	message: string;
}

export interface StickerRequest {
	stickerLocations: StickerLocations[];
	pageNumber: number;
}

export interface IMyJournal {
	_id?: string;
	pageNumber: number;
	stickerLocations: StickerLocations[];
}

export interface StickerLocations {
	location: Location;
	stickerId: string;
	stickerUrl: string;
}

export enum Location {
	TOP = 'TOP',
	MIDDLE_LEFT = 'MIDDLE_LEFT',
	MIDDLE_RIGHT = 'MIDDLE_RIGHT',
	BOTTOM_LEFT = 'BOTTOM_LEFT',
	BOTTOM_RIGHT = 'BOTTOM_RIGHT'
}

export const myJournalApi = createApi({
	reducerPath: 'myJournalApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		updateMyJournal: builder.mutation<MyJournalResponse, StickerRequest[]>({
			query: (body) => ({
				url: 'api/my-journal/update-my-journal',
				method: 'PUT',
				body
			})
		}),
		addNewPage: builder.mutation<MyJournalResponse, { pageNumber: number }>({
			query: ({ pageNumber }) => ({
				url: `api/my-journal/add-new-page/${pageNumber}`,
				method: 'POST'
			})
		}),
		deletePage: builder.mutation<MyJournalResponse, { pageId: string }>({
			query: ({ pageId }) => ({
				url: `api/my-journal/delete-page/${pageId}`,
				method: 'DELETE'
			})
		})
	})
});

export const { useUpdateMyJournalMutation, useAddNewPageMutation, useDeletePageMutation } = myJournalApi;
