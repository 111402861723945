import { createApi } from '@reduxjs/toolkit/query/react';
import { IActiveDay, IDataRange, ITable } from '../../types/myTables.types';
import { IAchievement } from '../../types/achievement.types';
import { baseQueryWithReauth } from './customBaseQuery';

interface TablesResponse {
	tables: ITable[];
	message: string;
	pagination: {
		page: number;
		totalPages: number;
		totalTables: number;
	};
}

interface TableResponse {
	data: ITable;
	achievements: IAchievement[];
	message: string;
}

export interface CreateTableRequest {
	title: string;
	isTablePublic: boolean;
	dataRanges: IDataRange[];
}

export interface UpdateActiveDaysRequest {
	id: string;
	activeDays: IActiveDay[];
}

export const myTablesApi = createApi({
	reducerPath: 'myTablesApi',
	baseQuery: baseQueryWithReauth,
	endpoints: (builder) => ({
		createTable: builder.mutation<TableResponse, CreateTableRequest>({
			query: (body) => ({
				url: 'api/my-tables/create-table',
				method: 'POST',
				body
			})
		}),
		getTables: builder.query<TablesResponse, { page?: number; limit?: number; query?: string }>({
			query: ({ page, limit, query }) => `api/my-tables/get-tables?q=${query}&page=${page}&limit=${limit}`
		}),
		deleteTable: builder.mutation<{ message: string }, string>({
			query: (id) => ({
				url: `api/my-tables/delete-table/${id}`,
				method: 'DELETE'
			})
		}),
		getTable: builder.query<TableResponse, string>({
			query: (id) => `api/my-tables/get-table/${id}`
		}),
		updateIsTablePublic: builder.mutation<TableResponse, string>({
			query: (id) => ({
				url: `api/my-tables/update-is-table-public/${id}`,
				method: 'PUT'
			})
		}),
		updateActiveDays: builder.mutation<TableResponse, string>({
			query: (id) => ({
				url: `api/my-tables/update-active-days/${id}`,
				method: 'PUT'
			})
		}),
		updateTableNotes: builder.mutation<TableResponse, { id: string; notes: string }>({
			query: ({ id, notes }) => ({
				url: `api/my-tables/update-table-notes/${id}`,
				method: 'PUT',
				body: { notes }
			})
		}),
		deleteTodaysColor: builder.mutation<TableResponse, { dataRangeId?: string; activeDayId?: string }>({
			query: ({ dataRangeId, activeDayId }) => ({
				url: `api/my-tables/delete-todays-color/${dataRangeId}/${activeDayId}`,
				method: 'DELETE'
			})
		})
	})
});

export const {
	useCreateTableMutation,
	useLazyGetTablesQuery,
	useDeleteTableMutation,
	useUpdateIsTablePublicMutation,
	useLazyGetTableQuery,
	useUpdateActiveDaysMutation,
	useUpdateTableNotesMutation,
	useDeleteTodaysColorMutation
} = myTablesApi;
